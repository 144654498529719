import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'

import SelectOrganization from './SelectOrganization'
import CreateAccount from './CreateAccount'
import MonthlyRecurring from './MonthlyRecurring'
import RoundingAccount from './RoundingAccount'
import FundingAccount from './FundingAccount'
import Finalize from './Finalize'

import useGlobal from 'store'
import context from 'lib/context'



export default function VerticalLinearStepper () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const [activeStep, setActiveStep] = React.useState(0)

  const steps = [
    {
      label: 'Select an Organization to Support',
      description: SelectOrganization,
      myRef: React.useRef(null),
      okToContinue: () => {
        if (!context.isEmpty(globalState.form.supported)) {
          return true
        }
        return false
      }
    },
    {
      label: 'Create an Account',
      description: CreateAccount,
      myRef: React.useRef(null),
      okToContinue: () => {
        const { form } = globalState

        if (!form.first_name) return false
        if (!form.last_name) return false
        if (!form.email_address) return false
        if (!form.password) return false
        if (!form.password_confirm) return false
        return true
      }
    },
    {
      label: 'Attach a Funding Account',
      description: FundingAccount,
      myRef: React.useRef(null),
      okToContinue: () => {

      }
    },
    {
      label: 'Attach a Rounding Account',
      description: RoundingAccount,
      myRef: React.useRef(null),
      okToContinue: () => {

      }
    },
    {
      label: 'Create a Recurring Donation',
      description: MonthlyRecurring,
      myRef: React.useRef(null),
      okToContinue: () => {

      }
    },
    {
      label: 'Finished!',
      description: Finalize,
      myRef: React.useRef(null),
      okToContinue: () => {

      }
    },
  ]
  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (steps[prevActiveStep].okToContinue()) {
        const num = prevActiveStep + 1
        steps[num].myRef.current.scrollIntoView()
        return num
      }
      return prevActiveStep
    })
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const num = prevActiveStep - 1
      steps[num].myRef.current.scrollIntoView()
      return num
    })
  }

  const handleReset = () => {
    steps[0].myRef.current.scrollIntoView()
    setActiveStep(0)
  }
  return (
    <>
      {/* <AppBar position="sticky">
        <MobileStepper
          variant="dots"
          steps={6}
          position="static"
          activeStep={activeStep}
          sx={{ flexGrow: 1 }}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </AppBar> */}
      <Box sx={{
        height: '100%',
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10vh',
        marginBotton: '10vh',
        textAlign: 'center',
        maxWidth: '750px'
      }}>

        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (

            <Step key={step.label} active>
              <div ref={step.myRef} >
                <StepLabel
                  optional={
                    index === 5 ? (
                      <Typography variant="caption">Last step</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  <Box sx={{ mb: 2 }}>
                    <step.description />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </div>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
    </>
  )
}
