import * as React from 'react'
import useGlobal from 'store'
import Header from 'components/Header'
import context from 'lib/context'
import Card from './MonthlyRecurringSettingsCard'

import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
} from '@mui/material'

function Component () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { rows: monthly_recurring_settings } = globalState.repo.monthly_recurring_settings || { undefined }

  React.useEffect(() => {
    if (context.isDefined(monthly_recurring_settings)) return
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-monthly-recurring-settings'
    })
  }, [
    globalActions.api,
    monthly_recurring_settings
  ])


  const handleAddNew = () => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'add-monthly-recurring-settings',
    })
  }

  return <>
    <Header label='Monthly Recurring Settings' />
    <Container
      sx={{
        paddingBottom: 3,
        paddingTop: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='h4' sx={{ m: 1 }}>
          Monthly Recurring Settings
        </Typography>
        <Typography variant="body1" sx={{ m: 1 }}>
          You will be charged once-per-month to your funding card, and the minimum donation is ${context.formatMoney(globalState.settings.minimumCharge)}.
        </Typography>
        <Typography variant="caption" sx={{ m: 1 }}>
          If you create multiple donations for the same organization, please note you will be charged multiple times.
        </Typography>
      </Box>
      <Box sx={{ position: 'relative', p: 3 }}>
        {context.isDefined(monthly_recurring_settings) &&
          context.isNotEmpty(monthly_recurring_settings) ?
          monthly_recurring_settings.map((row, idx) => {
            return <Card key={context.genKey(row)} row={row} idx={idx} sx={{ m: 1 }} />
          }) : <Paper>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 5
              }}
            >
              You do not have any monthly recurring donations set up.
            </Box>
          </Paper>}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button variant='outlined' onClick={handleAddNew}>
          Add New Monthly Recurring Donation
        </Button>

      </Box>
    </Container>
  </>
}

export default Component