/**
 * Display a spinner during API calls. A semaphore value is incremented. If the semaphore value is > 0, display spinner.
 * @param {*} store
 * @param {*} title
 */
export const spinUp = (store, title) => {
  const { apiSpinner } = store.state.controls
  const newCount = ++apiSpinner.count
  const newState = newCount > 0
  const newTitle = [title, ...apiSpinner.title]
  store.actions.control.mergeState({
    controls: {
      apiSpinner: {
        state: newState,
        count: newCount,
        title: newTitle
      }
    }
  }, true)
}

/**
 * Hide a spinner after API call completes. A semaphore value is decremented.
 * If the semaphore value is > 0, continue to display spinner until the value reaches 0.
 * @param {*} store
 */
export const spinDown = (store) => {
  const { apiSpinner } = store.state.controls
  const newCount = --apiSpinner.count
  const newState = newCount > 0
  const newTitle = [...apiSpinner.title]
  newTitle.shift()
  store.actions.control.mergeState({
    controls: {
      apiSpinner: {
        state: newState,
        count: newCount,
        title: newTitle
      }
    }
  }, true)
}
