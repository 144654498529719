import * as React from 'react'
import 'braintree-web'
import DropIn from 'braintree-web-drop-in-react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import useGlobal from 'store'

export default function Component () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()

  const { environment } = globalState.controls
  const { client_token } = globalState.braintree


  React.useEffect(() => {
    if (!client_token) {
      globalActions.api.performApi({
        apiName: 'Public',
        apiPath: '/public',
        apiAction: 'get-braintree-token'
      })
    }
    // eslint-disable-next-line
  }, [globalActions.api, client_token])

  if (!client_token) return null



  return (
    <Container component='main' maxWidth='lg'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        Select a payment method
        <DropIn
          options={{
            authorization: client_token,
            card: {},
            paypal: { flow: 'vault' },
            dataCollector: {
              kount: { environment: environment === 'production' ? 'production' : 'sandbox' }
            }
          }}
          //onInstance={(instance) => { setInstance(instance) }}
          onChange={() => { }}
        />
      </Box>
    </Container>
  )
}