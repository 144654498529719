import * as React from 'react'
import useGlobal from 'store'
import { DataGrid } from '@mui/x-data-grid'
import context from 'lib/context'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import Button from '@mui/material/Button'

function Component () {
  // eslint-disable-next-line no-unused-vars
  const [globalState, globalActions] = useGlobal()
  const { rows: taxReports } = globalState.repo.tax_reports || { undefined }
  const [pageSize, setPageSize] = React.useState(5)
  React.useEffect(() => {
    if (!!taxReports) return
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-tax-report-data'
    })
    // eslint-disable-next-line
  }, [globalActions.api])

  const onDownload = (year) => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-tax-pdf',
      apiPayload: {
        year
      }
    })
  }

  const columns = [
    {
      field: 'effective_year',
      headerName: 'Year',
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'sum',
      headerName: 'Net Donation Amount',
      renderCell: (params) => (
        <>${context.formatMoney(params.value)}</>
      ),
      width: 170,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'count',
      headerName: 'Number of Donations',
      headerAlign: 'center',
      align: 'center',
      width: 170,
    },
    {
      field: 'id',
      headerName: 'Download Report',
      renderCell: (params) => (
        <>
          <Button onClick={() => {
            onDownload(params.row.effective_year)
          }}>
            <PictureAsPdfIcon />
          </Button>
        </>
      ),
      headerAlign: 'center',
      align: 'center',
      width: 170,
    },
  ]

  if (!taxReports) return null

  return (
    <>
      <DataGrid
        rows={taxReports}
        columns={columns}
        disableSelectionOnClick
        autoHeight
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
      />
    </>
  )
}

export default Component
