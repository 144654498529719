import * as React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'

import Header from 'components/Header'
import SupportedOrg from './SupportedOrg'
import FundingAccount from './FundingAccount'
import RoundingAccount from './RoundingAccount'
import DisplayText from './YouHaveRounding'
import useGlobal from 'store'
import context from 'lib/context'

export default function Component () {
  // eslint-disable-next-line no-unused-vars
  const [globalState, globalActions] = useGlobal()

  const { current_user } = globalState.repo || { undefined }
  const { current_client } = globalState.repo || { undefined }

  if (context.isUndefined(current_user)) return

  return (<>
    {context.isDefined(current_client) &&
      current_client.allow_product_swipegive ?
      <Header col1={<DisplayText />} /> :
      <Header label='Donation Dashboard' title='Dashboard' />
    }
    <Container
      name="dashboard-container"
      sx={{
        backgroundColor: 'palette.background.dark',
        paddingBottom: 3,
        paddingTop: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <SupportedOrg />
          </Grid>
          <Grid item lg={6} xs={12}>
            <FundingAccount />
          </Grid>
          {context.isDefined(current_client) &&
            current_client.allow_product_swipegive &&
            <Grid item lg={6} xs={12}>
              <RoundingAccount />
            </Grid>
          }
        </Grid>
      </Box>
    </Container>
  </>)
}
