import _ from 'underscore'
import * as React from 'react'
import context from 'lib/context'
import useGlobal from 'store'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormLabel,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material'

export default function Component ({ row, idx, sx }) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { current_user } = globalState.repo || { undefined }
  const { rows: monthly_recurring_settings } = globalState.repo.monthly_recurring_settings || { undefined }
  const { active: customers } = globalState.repo.customers || { undefined }

  React.useEffect(() => {
    if (context.isTrue(customers)) return
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-customers'
    })
  }, [
    globalActions.api,
    customers
  ])

  const sendUpdate = _.debounce((name, value) => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'update-monthly-recurring-settings',
      apiPayload: {
        sys_id: row.sys_id,
        name,
        value
      },
    })
  }, 1000)

  const handleDeleteRecurring = () => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'delete-monthly-recurring-settings',
      apiPayload: {
        sys_id: row.sys_id
      },
    })
  }

  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    const rows = monthly_recurring_settings
    rows[idx][name] = value
    if (name === 'recurring_amount_type') {
      if (value === 'average') {
        rows[idx]['recurring_amount'] = 0
        globalActions.api.performApi({
          apiName: 'Events',
          apiPath: '/event',
          apiAction: 'update-monthly-recurring-settings',
          apiPayload: {
            sys_id: row.sys_id,
            name: 'recurring_amount',
            value: 0
          },
        })
      }
    }
    globalActions.control.mergeState({
      repo: {
        monthly_recurring_settings: {
          rows
        }
      }
    })
    sendUpdate(name, value)
  }
  if (context.isUndefined(current_user)) return null
  if (context.isUndefined(customers)) return null

  return (
    <Card sx={sx}>
      <CardContent>
        <form autoComplete="off">
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="supported-org">Supported Organization</InputLabel>
              <Select
                labelId="supported-org"
                name="client_id"
                id="client_id"
                value={row.client_id || ''}
                label="Supported Organization"
                onChange={handleChange}
              >
                <MenuItem value=''>
                  Support My Default Organization
                </MenuItem>
                {customers.map(item => {
                  return <MenuItem
                    key={context.genKey(item)}
                    value={item.client_id}>
                    {item.client_name}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </Box>
        </form>
        <FormControl>
          <FormLabel id='demo-controlled-radio-buttons-group'>Donation Amount</FormLabel>
          <RadioGroup
            aria-labelledby='demo-controlled-radio-buttons-group'
            name='recurring_amount_type'
            value={row.recurring_amount_type}
            onChange={handleChange}
          >
            <FormControlLabel value='average' control={<Radio />} label={' Match the CaringCent Rounding Average: $' + globalState.settings.defaultRecurring} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <FormControlLabel value='custom' control={<Radio />} label='  Custom Amount:' />
              <TextField
                name="recurring_amount"
                label="Custom Amount"
                value={row.recurring_amount}
                onChange={handleChange}
                sx={{
                  display: row.recurring_amount_type === 'custom' ? 'block' : 'none'
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Box>
          </RadioGroup>
        </FormControl>
      </CardContent>
      <CardActions>
        <Button size="small" color='secondary' onClick={handleDeleteRecurring}>Delete</Button>
      </CardActions>
    </Card>
  )
}