import * as React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import useGlobal from 'store'

export default function Component () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { notifications = {} } = globalState

  React.useEffect(() => {
    globalActions.control.signOut()
    // eslint-disable-next-line
  }, [])

  let start = new Date(notifications.start)

  return (
    <Container component='main' maxWidth='md'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {start > new Date() ? <>
          <Typography component='h1' variant='h5'>
            Site maintenance will begin {notifications.start}
          </Typography>
        </> : <>
          <Typography variant='body2' >
            CaringCent is down for planned maintenance now.
          </Typography>
        </>}
      </Box>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='body2' >
          We've got something special in store for you.
        </Typography>

        <Typography variant='body2' >
          And we can't wait for you to see it.
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='body2' >
          We expect to be back on {notifications.end}
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <img
              alt="Logo"
              src="https://secureservercdn.net/45.40.145.151/757.56c.myftpupload.com/wp-content/uploads/2020/05/logo-horizontal.jpg"
              style={{
                width: '90%',
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <img
              alt='Logo'
              src='https://secureservercdn.net/45.40.145.151/757.56c.myftpupload.com/wp-content/uploads/2020/05/CaringCentLogo_College-Atheltics_logo.png'
              style={{
                width: '89%',
              }}
            />
          </Grid>
        </Grid>
      </Box>

    </Container >
  )
}