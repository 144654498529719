import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import context from 'lib/context'
import useGlobal from 'store'

export default function SignIn () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant='h3'>
          Set Up Monthly Recurring Donation
        </Typography>
        <Typography variant='h5'>
          Manage Monthly Recurring Donation Settings
        </Typography>
        <Typography variant='body1'>
          Your funding credit card will be used for this monthly donation.
          You will be charged once a month on our billing cycle, and it
          will continue until you turn your recurring donation off on this page.
          The minimum recurring donation is ${context.formatMoney(globalState.settings.minimumCharge)}.
        </Typography>
      </Box>
    </Container>
  )
}