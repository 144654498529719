import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import AddCard from './AddCard'
import ShowCards from './ShowCards'
import Instructions from './Instructions'

import Header from 'components/Header'
function Component () {

  return <>
    <Header label='Funding Account' />
    <Container
      sx={{
        backgroundColor: 'palette.background.dark',
        paddingBottom: 3,
        paddingTop: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Instructions />
        <ShowCards />
        <AddCard />
      </Box>
    </Container>
  </>
}

export default Component