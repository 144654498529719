import * as React from 'react'

function Component (props) {
  return (
    <>
      <div widget='parallax' style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            zIndex: 2,
            width: '100%'
          }}
        >
          {props.children}
        </div>
        <div
          style={{
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            margin: '0',
            backgroundImage: 'url(' + props.backgroundImage + ')',
            minHeight: props.minHeight
          }}
        />
      </div>
    </>
  )
}

export default Component
