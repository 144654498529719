import * as React from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import useGlobal from 'store'

export default function SignIn() {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const handleSubmit = (event) => {
    event.preventDefault()
    console.log(globalState.form)
  }

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button onClick={handleSubmit} sx={{ mt: 1, mr: 1 }}>
          Create Account
        </Button>
      </Box>
    </Container>
  )
}