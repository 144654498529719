import * as React from 'react'
import {
  Button,
  Skeleton,
  Box,
  Card,
  CardContent,
  CardActions,
  Typography
} from '@mui/material'
import useGlobal from 'store'
import { Link } from 'react-router-dom'
import context from 'lib/context'

export default function Component () {
  // eslint-disable-next-line no-unused-vars
  const [globalState, globalActions] = useGlobal()

  const {
    rows: paymentProfiles,
    lastFetch
  } = globalState.repo.payment_profiles || { undefined }

  React.useEffect(() => {
    if (context.isDefined(lastFetch)) return
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-payment-profiles'
    })
    // eslint-disable-next-line
  }, [globalActions.api, lastFetch])

  if (!context.isDefined(paymentProfiles)) return <Skeleton variant="text" width='100%' height={150} />

  const renderExpiration = (param) => {
    if (param.src == 'AN') {
      return <>
        <Box sx={{ pt: 1, fontSize: '11px' }}>
          <span style={{ color: 'red', fontSize: '.8em' }}>Replace by 3/31</span>
        </Box>
      </>
    }
    let input = param.expiration_date
    if (!input) return
    if (input.includes('-')) {
      let parts = input.split('-')
      return <>
        <Box sx={{ pt: 1, fontSize: '11px' }}>
          expires {parts[1]}/{parts[0]}
        </Box>
      </>
    }
    return <>
      <Box sx={{ pt: 1, fontSize: '11px' }}> expiration date N/A</Box></>
  }

  const renderSwitch = (param) => {
    switch (param.card_type) {
      case 'PayPal':
        return <>
          {param.card_number}
          <Box sx={{ pt: 1, fontSize: '11px' }}>PayPal account</Box>
        </>
      default:
        return param.card_type + ' ending in ' + param.card_number
    }
  }

  return (
    <Box sx={{ minWidth: 275, height: '100%' }}>
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}>
        <CardContent>
          <Typography align="center" sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
            Funding Account
          </Typography>
          {context.isDefined(paymentProfiles) && paymentProfiles.length ? <>
            <Typography align="center" component='div' sx={{ mt: 3 }}>
              {renderSwitch(paymentProfiles[0])}
              {renderExpiration(paymentProfiles[0])}
            </Typography>
          </> : <>
            <Typography align="center" component='div' sx={{ mt: 3 }}>
              <Link to='/donor/funding-account' style={{ textDecoration: 'none' }}>
                <Button size='small'>
                  Please add a funding account
                </Button>
              </Link>
            </Typography>
          </>
          }
        </CardContent>
        <CardActions>
          <Link to='/donor/funding-account' style={{ textDecoration: 'none' }}>
            <Button size='small'>View/Update Accounts</Button>
          </Link>
        </CardActions>
      </Card>
    </Box >
  )
}