import * as React from 'react'

import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Toolbar,
  Typography,
  Box
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import useGlobal from 'store'
import context from 'lib/context'
import { Link } from 'react-router-dom'
import RegistrationChecklist from 'views/MyDashboard/RegistrationChecklist'

function Header ({ label, col1, col2, col3, children, ...props }) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { current_user } = globalState.repo || { undefined }
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return <>
    {props.title && <>
      <Helmet>
        <title>{props.title} - CaringCent LLC - Your national partner for College Athletics, Fund Raising, and Donor Acquisition</title>
      </Helmet>
    </>}
    <AppBar
      component='div'
      position='sticky'
      elevation={0}
      sx={{
        zIndex: 1000,
        backgroundImage: context.gradient
      }}
    >
      <Toolbar>
        <Grid container alignItems='center' spacing={1}>
          <Grid sx={{ display: { sm: 'none', xs: 'inline' } }} item>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={globalActions.control.toggleNavigator}
              edge='start'
              size="large">
              <MenuIcon fontSize='large' />
            </IconButton>
          </Grid>
          {label && <>
            <Grid item xs>
              <Typography color='secondary' variant='h5' component='span'>
                {label}
              </Typography>
            </Grid>
            <Grid item xs />
          </>}
          {col1 && <Grid item xs>{col1}</Grid>}
          {col2 && <Grid item xs>{col2}</Grid>}
          {col3 && <Grid item xs>{col3}</Grid>}
          {children}
          <Grid item>
            <Box
              component='span'
              sx={{ display: { xs: 'none', md: 'block' } }}
            >
              {!!current_user && current_user.full_name}
            </Box>
          </Grid>
          <Grid item>
            <IconButton color='inherit' onClick={handleClick} size="large">
              <AccountCircleIcon sx={{ color: 'white' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {!!current_user &&
        <MenuItem>
          <Typography variant='caption'>
            {current_user.email_address}
          </Typography>
        </MenuItem>
      }
      <Divider />
      <MenuItem onClick={() => { navigate('/donor/my-profile') }}>My Profile</MenuItem>
      {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
      <MenuItem onClick={() => { globalActions.control.signOut() }}>Logout</MenuItem>
    </Menu>
    {current_user && current_user.status === 'suspended' &&
      <Box sx={{
        padding: 2,
        backgroundColor: '#f44336',
        color: 'white',
      }}>
        Your account is currently in a Suspended state,
        which means your supported organization will not
        receive any recurring donations from you.
        If you wish to re-activate your account, you can do so
        in your profile. <Link to='/donor/my-profile' style={{ textDecoration: 'none' }}>
          <Button size='small'>Edit My Profile</Button>
        </Link>
      </Box>
    }
    {current_user && current_user.status === 'closed' &&
      <Box sx={{
        padding: 2,
        backgroundColor: '#f44336',
        color: 'white',
      }}>
        Your account is currently in a Closed state,
        which means your supported organization will not
        receive any donations from you.
        If you wish to re-activate your account, you can do so
        in your profile.
        <Link to='/donor/my-profile' style={{ textDecoration: 'none' }}>
          <Button
            size='small'
          >
            Edit My Profile
          </Button>
        </Link>
      </Box>
    }
    <RegistrationChecklist />
  </>
}

export default Header