import * as React from 'react'
import { Link, Navigate } from 'react-router-dom'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import useGlobal from 'store'
import PrivacyPolicyPopup from 'views/Legal/PrivacyPolicyPopup'
import TermsOfServicePopup from 'views/Legal/TermsOfServicePopup'

import context from 'lib/context'
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import _ from 'underscore'
export default function SignIn () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const formElement = React.useRef()
  const { errors = {}, result } = globalState
  const { active: customers } = globalState.repo.customers || { undefined }

  React.useEffect(() => {
    globalActions.control.signOut()
  }, [globalActions.control])

  React.useEffect(() => {
    if (context.isUndefined(customers)) {
      globalActions.api.performApi({
        apiName: 'Public',
        apiPath: '/public',
        apiAction: 'get-customers'
      })
    }
  }, [
    globalActions.api,
    customers
  ])

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(formElement.current)
    globalActions.api.performApi({
      apiName: 'Public',
      apiPath: '/public',
      apiAction: 'register-new-account',
      apiPayload: {
        form: Object.fromEntries(formData)
      }
    })
  }

  const onChange = _.debounce((event) => {
    event.preventDefault()
    const formData = new FormData(formElement.current)
    globalActions.api.performApi({
      apiName: 'Public',
      apiPath: '/public',
      apiAction: 'check-registration-field',
      apiPayload: {
        field: event.target.name,
        value: event.target.value,
        form: Object.fromEntries(formData)
      }
    })
  }, 1000)

  if (result === 'success') {
    return (<Navigate to="/go/success" />)
  }

  if (context.isUndefined(customers)) return

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Create Account
        </Typography>
        <Box
          autoComplete='off'
          ref={formElement}
          component='form'
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}>
          {/* <TextField
            margin='normal'
            required
            fullWidth
            id='username'
            label='Username'
            name='username'
            onChange={onChange}
            autoComplete='off'
            helperText={errors.username}
            error={!!errors.username}
          /> */}


          <FormControl fullWidth sx={{
            pb: 1
          }}>
            <InputLabel id="supported-org">Supported Organization</InputLabel>
            <Select
              labelId="supported-org"
              name="client_id"
              id="client_id"
              label="Supported Organization"
              onChange={onChange}
            >
              <MenuItem value=''>
                Support My Default Organization
              </MenuItem>
              {customers.map(item => {
                return <MenuItem
                  key={context.genKey(item)}
                  value={item.client_id}>
                  {item.client_name}
                </MenuItem>
              })}
            </Select>
          </FormControl>


          <TextField
            margin='normal'
            required
            fullWidth
            id='email_address'
            label='Email Address'
            name='email_address'
            onChange={onChange}
            autoComplete='off'
            helperText={errors.email_address}
            error={!!errors.email_address}
          />

          <TextField
            margin='normal'
            required
            fullWidth
            id='first_name'
            label='First Name'
            name='first_name'
            autoComplete='off'
            onChange={onChange}
            helperText={errors.first_name}
            error={!!errors.first_name}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            id='last_name'
            label='Last Name'
            name='last_name'
            autoComplete='off'
            onChange={onChange}
            helperText={errors.last_name}
            error={!!errors.last_name}
          />

          {/* <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            onChange={onChange}
            autoComplete='off'
            helperText={errors.password || 'At least eight (8) characters, one upper, one lower, and one number.'}
            error={!!errors.password}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password_confirm'
            label='Password Confirm'
            type='password'
            id='password_confirm'
            onChange={onChange}
            autoComplete='off'
            helperText={errors.password_confirm}
            error={!!errors.password_confirm}
          /> */}
          <Typography variant='caption'>
            By creating a CaringCent Account, you agree to CaringCent's <TermsOfServicePopup label={{
              display: 'inline', textDecoration: 'underline'
            }} />            and <PrivacyPolicyPopup label={{
              display: 'inline', textDecoration: 'underline'
            }} />.
          </Typography>
          <Box>
            <Button
              variant='contained'
              onClick={handleSubmit} sx={{ mt: 1, mr: 1 }}>
              Create Account
            </Button>
          </Box>
          <Box>
            <Box sx={{ top: 5, position: 'relative', display: 'inline' }}>Already have an account?</Box>
            <Link
              to='/donor/dashboard'
              style={{ textDecoration: 'none' }}
            >
              <Button sx={{ mt: 1, mr: 1 }}>
                Sign In
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}