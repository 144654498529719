import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import useGlobal from 'store'
import context from 'lib/context'
import { useSearchParams } from 'react-router-dom'

const defaultDescription = `
  <p> Did you know that the average consumer donates more than $10.00 a month in checkout counter change to charities promoted by merchants? What if you could choose the nonprofit that receives this donation...and track the tax deduction? Now you can!</p>
  <p> With CaringCent, supporting your nonprofit organization is easy, fun, and affordable. We believe in "Your Change, Your Choice!" You can now register your credit or debit card with CaringCent and when you shop, your purchase total will be rounded up to the nearest dollar. The difference is then donated to support the cause you care about!</p>
  <p> With CaringCent, you can:</p>
  <ol>
    <li> Track and manage your monthly donation, including setting a monthly maximum contribution</li>
    <li> Retain the tax benefit - CaringCent keeps track of your donations and provides receipts</li>
    <li> Affordably support your favorite nonprofit!</li>
  </ol>
`

function Component () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()

  const { supportedOptions } = globalState
  const { supported } = globalState.form
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams()
  // eslint-disable-next-line
  const [slug, setSlug] = React.useState(searchParams.get('v'))

  const image = supported.large_logo_url && 'https://donate.caringcent.com' + supported.large_logo_url

  React.useEffect(() => {
    if (context.isEmpty(supportedOptions)) {
      globalActions.api.performApi({
        apiName: 'Public',
        apiPath: '/public',
        apiAction: 'get-clients',
        stateReducer: (store, response) => {
          if (response) {
            const supportedOptions = response.map((option) => {
              const firstLetter = option.client_name[0].toUpperCase()
              return {
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                ...option,
              }
            })
            globalActions.control.mergeState({ supportedOptions })
            if (slug) {
              for (const option of supportedOptions) {
                if (option.vanity_url === slug.toLowerCase()) {
                  const newState = { ...globalState }
                  newState.form.supported = option || {}
                  globalActions.control.setState(newState)
                  return
                }
              }
            }
          }
        }
      })
    }
    // eslint-disable-next-line
  }, [globalActions.api, supportedOptions])

  if (context.isEmpty(supportedOptions)) { return null }

  return (
    <>
      <Box
        style={{
          minHeight: '70vh'
        }}
        component="div"
      >
        <Autocomplete
          id='grouped-demo'
          options={supportedOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.client_name || ''}
          sx={{ width: 300 }}
          noOptionsText='Please Wait...'
          renderInput={(params) => {
            return <TextField {...params} label={'Please select an oganization to support...'} />
          }}
          onChange={(event, option) => {
            const newState = { ...globalState }
            newState.form.supported = option || {}
            globalActions.control.setState(newState)
          }}
          isOptionEqualToValue={(option, value) => {
            return option.sys_id === value.sys_id
          }}
          value={supported}
          key={option => context.genKey(option)}
        />
        {!context.isEmpty(supported) && <>
          <div> You have selected {supported.client_name}</div>
          {image && <img alt='selected organization' src={image} />}
          <div dangerouslySetInnerHTML={{ __html: supported.long_description || defaultDescription }} />
        </>
        }
      </Box>
    </>
  )
}

export default Component
