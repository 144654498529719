import * as React from 'react'
import useGlobal from 'store'
import { DataGrid } from '@mui/x-data-grid'
import context from 'lib/context'
import {
  Button,
  Skeleton
} from '@mui/material'
import Link from './Link'

export default function DenseTable () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { rows: plaidAccounts } = globalState.repo.plaid_accounts || { undefined }

  React.useEffect(() => {
    if (context.isUndefined(plaidAccounts)) {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'get-plaid-accounts'
      })
    }
    // eslint-disable-next-line
  }, [globalActions.api, plaidAccounts])

  if (context.isUndefined(plaidAccounts)) return null

  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 175 },
    { field: 'mask', headerName: 'Account' },
    { field: 'type', headerName: 'Type' },
    { field: 'subtype', headerName: 'SubType' },
    { field: 'item_count', headerName: 'Count' },
    {
      field: 'amount',
      headerName: 'Amount',
      renderCell: (params) => (
        <>${context.formatMoney(params.value)}</>
      )
    },
    {
      field: 'plaid_state',
      headerName: 'Plaid State',
      width: 130,
      renderCell: (params) => {
        let content = 'Active'
        if (params.row.error) {
          switch (params.row.error.error_code) {
            case 'ITEM_LOGIN_REQUIRED':
              content = <Link token={params.row.access_token}>Login Required</Link>
              break
            default:
              content = 'Error'
              break
          }
        }
        return (
          <strong>
            {content}
          </strong>
        )
      },
    },
    {
      field: 'delete',
      headerName: 'Delete',
      renderCell: (params) => (
        <strong>
          <Button
            color="primary"
            size="small"
            onClick={() => {
              globalActions.api.performApi({
                apiName: 'Events',
                apiPath: '/event',
                apiAction: 'plaid-account-remove',
                apiPayload: {
                  sys_id: params.row.sys_id
                }
              })
            }}
          >
            Delete
          </Button>
        </strong>
      )
    }
  ]

  if (globalState.controls.environment !== 'production') {
    columns.push({
      field: 'reset',
      headerName: '(Sandbox Reset)',
      width: 175,
      renderCell: (params) => {
        if (params.row.error) {
          return null
        } else {
          return (
            <strong>
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  globalActions.api.performApi({
                    apiName: 'Events',
                    apiPath: '/event',
                    apiAction: 'sandbox-reset-plaid-login',
                    apiPayload: {
                      access_token: params.row.access_token
                    }
                  })
                }}
              >
                Reset
              </Button>
            </strong>
          )
        }
      }
    })
  }
  return (
    <div style={{ margin: 15, width: '100%' }}>
      {context.isDefined(plaidAccounts) ?
        <DataGrid
          rows={plaidAccounts}
          columns={columns}
          // checkboxSelection
          autoHeight
          hideFooter
          density='compact'
          disableSelectionOnClick
        />
        : <Skeleton variant="text" width='100%' height={150} />
      }

    </div>
  )

}
