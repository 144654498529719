import * as React from 'react'
import context from 'lib/context'
import useGlobal from 'store'
import RallyMetricCard from './RallyMetricCard'

import {
  Box,
  Card,
  CardContent,
  Grid,
} from '@mui/material'

export default function Component ({ row, idx, sx }) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { current_user } = globalState.repo || { undefined }
  const { lookup: customers } = globalState.repo.customers || { undefined }
  const { lookup: formbuilder } = globalState.repo.form_builder_template || { undefined }

  const campaign = row['campaign_id']
  const template = formbuilder && formbuilder[campaign]

  React.useEffect(() => {
    if (context.isUndefined(customers)) {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'get-customers'
      })
    }
  }, [
    globalActions.api,
    customers
  ])

  React.useEffect(() => {
    if (context.isUndefined(formbuilder) || context.isUndefined(template)) {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'get-form-builder-template',
        apiPayload: { campaign }
      })
    }
  }, [
    globalActions.api,
    template,
    campaign,
    formbuilder
  ])

  if (context.isUndefined(current_user)) return
  if (context.isUndefined(customers)) return
  if (context.isUndefined(template)) return
  if (template.campaign_end_date) {
    const difference = new Date(template.campaign_end_date) - new Date()
    if (difference < 0) { return }
  }
  return (
    <Card sx={sx}>
      <CardContent>

        <form autoComplete="off">
          <Box sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            color: template.primary_color
          }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box sx={{
                  position: 'relative',
                  left: 25,
                }}>
                  <h2>{customers[row.client_id].client_name}</h2>
                  <Box sx={{
                    color: 'gray',
                    position: 'relative',
                    top: -85,
                    left: -25,
                    fontSize: '20px'
                  }}> Organization </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <h2>{row.campaign_id}</h2>
                <Box sx={{
                  color: 'gray',
                  position: 'relative',
                  top: -85,
                  left: -25,
                  fontSize: '20px'
                }}> Campaign </Box>
              </Grid>
              <Grid item xs={12}>
                {template.header_banner_image_url && <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    style={{ width: '95%' }}
                    src={template.header_banner_image_url}
                    alt='Campaign Logo' />
                </Box>
                }
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    style={{ width: '50%' }}
                    src={template.main_image_url}
                    alt='Campaign Logo' />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    position: 'relative',
                    top: 10,
                    fontSize: 18
                  }}>
                  <a href={template.share_url} target="_blank">
                    {template.share_url}
                  </a>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {row.children && row.children.map((metric, idx) => {
                  return <RallyMetricCard
                    key={context.genKey(metric)}
                    metric={metric}
                    sx={{ m: 1 }}
                    idx={idx}
                  />
                })}
              </Grid>
              {/* <Grid item xs={12}>
                {row.metrics && Object.keys(row.metrics).map((key, idx) => {
                  const val = row.metrics[key]
                  return <Box>
                    {key} {JSON.stringify(val)}
                  </Box>
                })}
              </Grid> */}
              {/* <Grid item xs={12}>
                {template.metrics && Object.keys(template.metrics).map((key, idx) => {
                  const val = row.metrics[key]
                  return <Box>
                    {key} {JSON.stringify(val)}
                  </Box>
                })}
              </Grid> */}
            </Grid>
          </Box>
        </form>
      </CardContent>
    </Card >
  )
}