import * as React from 'react'
import Typography from '@mui/material/Typography'
import useGlobal from 'store'

function Component () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  return <>
    <Typography variant='h4' sx={{ m: 1 }}>
      Funding Account
    </Typography>
    <Typography variant="body1" sx={{ m: 1 }}>
      This is the card that will be charged to fund your donations to your selected Nonprofit and is referred to as the “Funding” account. If you have more than
      one card listed and the default card is declined, we will attempt charge additonal cards until the transaction succeeds.
    </Typography>
  </>
}

export default Component