import * as React from 'react'
import useGlobal from 'store'
import context from 'lib/context'
import { DataGrid } from '@mui/x-data-grid'
import {
  Button,
  Skeleton
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

function Component () {
  const [duplicates, setDuplicates] = React.useState(false)
  const [expired, setExpired] = React.useState(false)
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const {
    rows: paymentProfiles,
    lastFetch,
  } = globalState.repo.payment_profiles || { undefined }

  React.useEffect(() => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-payment-profiles',
      apiPayload: {
        expired,
        duplicates
      }
    })
    // eslint-disable-next-line
  }, [globalActions.api, expired, duplicates])


  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'first_name', headerName: 'First Name' },
    { field: 'last_name', headerName: 'Last Name' },
    { field: 'card_number', headerName: 'Account' },
    { field: 'card_type', headerName: 'Type' },
    { field: 'src', headerName: 'Source', },
    {
      field: 'expiration_date', headerName: 'Expires',
      renderCell: (params) => {
        if (params.row.src === 'AN') {
          return <> <span style={{ color: 'red', fontSize: '.8em' }}>Replace by 3/31</span> </>
        } else {
          return (
            <strong>
              {params.value}
            </strong >
          )
        }
      },
    },
    // {
    //   field: 'foo',
    //   headerName: 'Update',
    //   width: 115,
    //   renderCell: (params) => {
    //     if (params.row.src === 'AN') {
    //       return
    //     } else if (params.row.src === 'AN') {
    //       return (<strong>
    //         <Button
    //           color="primary"
    //           size="small"
    //         >
    //           Update Card
    //         </Button>
    //       </strong>)
    //     }
    //   }
    // },
    {
      field: 'is_default',
      headerName: 'Default Payment',
      renderCell: (params) => {
        if (params.value) {
          return (<i>default</i>)
        } else {
          return (<strong>
            <Button
              color="primary"
              size="small"
              onClick={() => {
                globalActions.api.performApi({
                  apiName: 'Events',
                  apiPath: '/event',
                  apiAction: 'funding-make-default',
                  apiPayload: {
                    sys_id: params.row.sys_id
                  }
                })
              }}
            >
              Make Default
            </Button>
          </strong>)
        }
      }
    },
    {
      field: 'sys_id',
      headerName: '',
      renderCell: (params) => {
        if (params.row.is_default === true) {
          return <> </>
        } else {
          return (
            <strong>
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  globalActions.api.performApi({
                    apiName: 'Events',
                    apiPath: '/event',
                    apiAction: 'payment-profile-account-remove',
                    apiPayload: {
                      sys_id: params.value
                    },
                    stateReducer: (store, response) => {
                      globalActions.control.mergeState(response)
                      if (response.errors) {
                        alert(response)
                      } else {
                        globalActions.api.performApi({
                          apiName: 'Events',
                          apiPath: '/event',
                          apiAction: 'get-payment-profiles',
                          apiPayload: {
                            expired,
                            duplicates
                          }
                        })
                      }

                    },
                  })
                }}
              >
                Delete
              </Button>
            </strong >
          )
        }
      },
    },
  ]


  return <>
    <div style={{ margin: 15, width: '100%' }}>
      {context.isDefined(paymentProfiles) ?
        <>
          <FormControlLabel control={<Checkbox
            checked={duplicates}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setDuplicates(e.target.checked)
            }}
          />}
            label="Show duplicates" />
          <FormControlLabel control={<Checkbox
            checked={expired}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setExpired(e.target.checked)
            }}
          />}
            label="Show Expired" />
          <DataGrid
            rows={paymentProfiles}
            columns={columns}
            autoHeight
            hideFooter
            density='compact'
            disableSelectionOnClick
          />
        </> :
        <Skeleton variant="text" width='100%' height={150} />
      }

    </div>
  </>
}

export default Component