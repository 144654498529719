import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import useGlobal from 'store'
import context from 'lib/context'

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import * as Icons from '@mui/icons-material'
import { BuildInfo, Logo } from 'components'

import {
  //ServiceFeesPopup,
  PrivacyPolicyPopup,
  TermsOfServicePopup
} from 'views'


const legal = {
  py: 0,
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
}

const header = {
  py: 2,
  px: 3,
  color: 'white',
}

const item = {
  py: '2px',
  px: 4,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
}



export default function Navigator ({ ...props }) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()

  const { current_client, current_user } = globalState.repo || { undefined }

  const categories = [
    { id: 'Dashboard', icon: <Icons.Dashboard />, link: '/donor/dashboard' }
  ]
  categories.push({
    id: 'Profile', children: [
      { id: 'My Profile', icon: <Icons.AccountCircle />, link: '/donor/my-profile' },
      { id: 'Reports', icon: <Icons.TableChart />, link: '/donor/my-reports' }
    ]
  })

  const children = []

  if (current_user.status === 'active') {
    children.push({ id: 'Funding Account', icon: <Icons.CreditCard />, link: '/donor/funding-account' })
    if (context.isDefined(current_client) &&
      current_client.allow_product_monthly_recurring) {
      children.push({ id: 'Monthly Recurring', icon: <Icons.EventRepeat />, link: '/donor/recurring-settings' })
    }
    if (context.isDefined(current_client) &&
      current_client.allow_product_swipegive) {
      children.push({ id: 'Rounding Account', icon: <Icons.Receipt />, link: '/donor/rounding-account' })
      children.push({ id: 'Swipe Give', icon: <Icons.Swipe />, link: '/donor/rounding-settings' })
    }
    if (context.isDefined(current_client) &&
      current_client.allow_product_rallygive) {
      children.push({ id: 'Pledged Donations', icon: <Icons.Sports />, link: '/donor/rally-settings' })
    }
    if (context.isDefined(current_client) &&
      current_client.allow_product_spot_donation) {
      children.push({ id: 'One-Time Donation', icon: <Icons.VolunteerActivism />, link: '/donor/make-donation' })
    }
  }

  if (!context.isEmpty(children)) {
    categories.push({ id: 'Donations', children: children })
  }



  if (context.environment !== 'production') {
    categories.push(
      {
        id: 'Developer Settings',
        children: [
          { id: 'DataStore', icon: <Icons.Edit />, link: '/donor/datastore' },
        ],
      },
    )
  }

  return (<>
    <Drawer
      variant='permanent'
      {...props}
      sx={{
        '& .MuiPaper-root': {
          backgroundImage: context.gradientVert,
        }
      }}
    >
      <List disablePadding>
        <ListItem>
          <Logo style={{ maxHeight: 35 }} />
        </ListItem>
        {categories.map(({ id, children, icon, link }) => (
          <Box key={id}>
            {link ? <>
              <RouterLink
                style={{ textDecoration: 'none' }}
                to={link}
                onClick={globalActions.control.closeNavigator}
              >
                <ListItem sx={header}>
                  {icon ? <>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText sx={{ ml: 1 }}>{id}</ListItemText>
                  </> : <>
                    <ListItemText>{id}</ListItemText>
                  </>}
                </ListItem>
              </RouterLink>
            </> : <>
              <ListItem sx={header}>
                {icon ? <>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText sx={{ ml: 1 }}>{id}</ListItemText>
                </> : <>
                  <ListItemText>{id}</ListItemText>
                </>}
              </ListItem>
            </>}

            {children &&
              children.map(({ id: childId, icon, active, link }) => (
                <RouterLink
                  style={{ textDecoration: 'none' }}
                  to={link}
                  key={childId}
                  onClick={globalActions.control.closeNavigator}
                >
                  <ListItem disablePadding key={childId}>
                    <ListItemButton selected={active} sx={item}>
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText sx={{ ml: 1 }}>{childId}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </RouterLink>
              ))
            }
            <Divider />
          </Box>
        ))}
        <Box sx={{ p: 1 }} />
        <ListItem sx={legal}>
          <ListItemText><TermsOfServicePopup label={{
            fontSize: '90%'
          }} /></ListItemText>
        </ListItem>
        <ListItem sx={legal}>
          <ListItemText><PrivacyPolicyPopup label={{
            fontSize: '90%'
          }} /></ListItemText>
        </ListItem>
        {/* <ListItem sx={legal} >
          <ListItemText><ServiceFeesPopup label={{
            fontSize: '90%'
          }} /></ListItemText>
        </ListItem> */}
      </List>
      {context.environment !== 'production' && <BuildInfo />}
    </Drawer>
  </>
  )
}
