import * as React from 'react'
import { Navigate } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import useGlobal from 'store'
import PrivacyPolicyPopup from 'views/Legal/PrivacyPolicyPopup'
import TermsOfServicePopup from 'views/Legal/TermsOfServicePopup'
import _ from 'underscore'
import { PublicTopBar, PublicFooter } from 'components'
import context from 'lib/context'


export default function SignIn () {
  const params = context.getParams()
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const formElement = React.useRef()
  const { errors = {}, result } = globalState

  React.useEffect(() => {
    globalActions.control.signOut()
    // eslint-disable-next-line
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(formElement.current)
    globalActions.api.performApi({
      apiName: 'Public',
      apiPath: '/public',
      apiAction: 'unsubscribe-email',
      apiPayload: {
        form: Object.fromEntries(formData)
      }
    })
  }

  if (result === 'success') {
    return (<Navigate to="/go/success" />)
  }

  return (
    <>
      <PublicTopBar />
      <Container component='main' maxWidth='sm'>
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Unsubscribe
          </Typography>

          <Typography variant="body2" sx={{ mt: 1 }}>
            We want to stay in touch, but only in ways that you find helpful.
            If you unsubscribe from our account reminder emails, you will no longer
            receive notifications such as expiring funding cards. If your
            funding card is expired for more than 2 months, your account will be
            closed and your supported organization will no longer receive your
            financial support.
          </Typography>

          <Typography variant="body2" sx={{ mt: 1 }}>
            To unsubscribe from future email communications, enter your
            email address and click Unsubscribe when you are finished. In the
            future, you may change email preferences by signing in to your account.
          </Typography>

          <Typography variant="body2" sx={{ mt: 1 }}>
            Please review CaringCent's <TermsOfServicePopup label={{
              display: 'inline', textDecoration: 'underline'
            }} />            and <PrivacyPolicyPopup label={{
              display: 'inline', textDecoration: 'underline'
            }} /> for more information.
          </Typography>

          <Box
            autoComplete='off'
            ref={formElement}
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, width: '100%' }}
          >

            <TextField
              margin='normal'
              required
              fullWidth
              id='email_address'
              label='Email Address'
              name='email_address'
              defaultValue={params.email}
              autoComplete='off'
              helperText={errors.email_address}
              error={!!errors.email_address}
            />

            <Box>
              <Button
                variant='contained'
                onClick={handleSubmit} sx={{ mt: 1, mr: 1 }}>
                Unsubscribe
              </Button>
            </Box>

          </Box>
        </Box>
      </Container>
      <PublicFooter
        style={{ background: context.gradient }}
        sx={{
          pb: 3,
          position: 'fixed',
          left: 0,
          bottom: 0,
        }}
      />
    </>
  )
}