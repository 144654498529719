import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import useGlobal from 'store'
import _ from 'underscore'
import { VanityDetails } from 'components'
import context from 'lib/context'
import * as Views from 'views'

// const defaultDescription = `
//   <p> Did you know that the average consumer donates more than $10.00 a month in checkout counter change to charities promoted by merchants? What if you could choose the nonprofit that receives this donation...and track the tax deduction? Now you can!</p>
//   <p> With CaringCent, supporting your nonprofit organization is easy, fun, and affordable. We believe in "Your Change, Your Choice!" You can now register your credit or debit card with CaringCent and when you shop, your purchase total will be rounded up to the nearest dollar. The difference is then donated to support the cause you care about!</p>
//   <p> With CaringCent, you can:</p>
//   <ol>
//     <li> Track and manage your monthly donation, including setting a monthly maximum contribution</li>
//     <li> Retain the tax benefit - CaringCent keeps track of your donations and provides receipts</li>
//     <li> Affordably support your favorite nonprofit!</li>
//   </ol>
// `

const Loading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 5
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default function SignIn () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()

  const formElement = React.useRef()
  const {
    errors = {},
    supported,
    result
  } = globalState

  React.useEffect(() => {
    globalActions.control.signOut()
  }, [
    globalActions.control
  ])

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(formElement.current)
    globalActions.api.performApi({
      apiName: 'Public',
      apiPath: '/public',
      apiAction: 'register-new-account',
      apiPayload: {
        form: Object.fromEntries(formData),
        supported: supported
      }
    })
  }

  const onChange = _.debounce((event) => {
    event.preventDefault()
    const formData = new FormData(formElement.current)
    globalActions.api.performApi({
      apiName: 'Public',
      apiPath: '/public',
      apiAction: 'check-registration-field',
      apiPayload: {
        field: event.target.name,
        value: event.target.value,
        form: Object.fromEntries(formData)
      }
    })
  }, 1000)

  if (result === 'success') {
    return (<Views.SwipeGiveSuccess />)
  }
  console.log('top')

  return (
    <Container component='main' maxWidth='md'>
      <Box
        sx={{
          mt: 8,
          mb: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <VanityDetails />
        {context.isDefined(supported) ? <>
          {errors.error_header && <>
            <Typography component='h1' variant='h5' sx={{ fontColor: 'red' }}>
              {errors.error_header}
            </Typography>
          </>}
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Create Account
          </Typography>
          <Box
            autoComplete='off'
            ref={formElement}
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}>

            <TextField
              margin='normal'
              required
              fullWidth
              id='email_address'
              label='Email Address'
              name='email_address'
              onChange={onChange}
              autoComplete='off'
              helperText={errors.email_address}
              error={!!errors.email_address}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              id='first_name'
              label='First Name'
              name='first_name'
              autoComplete='off'
              onChange={onChange}
              helperText={errors.first_name}
              error={!!errors.first_name}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              id='last_name'
              label='Last Name'
              name='last_name'
              autoComplete='off'
              onChange={onChange}
              helperText={errors.last_name}
              error={!!errors.last_name}
            />
            <Typography variant='caption'>
              By creating a CaringCent Account, you agree to CaringCent's Terms of Service
              and Privacy Policy.
            </Typography>
            <Box>
              <Button
                variant='contained'
                onClick={handleSubmit} sx={{ mt: 1, mr: 1 }}>
                Create Account
              </Button>
            </Box>
          </Box>
        </> : <Loading />}

      </Box>
    </Container >
  )
}