import * as React from 'react'

import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import Header from 'components/Header'
import useGlobal from 'store'
import context from 'lib/context'

import { useNavigate, useLocation } from 'react-router-dom'

function Checkout () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { spotDonation } = globalState
  const { current_user } = globalState.repo || undefined
  const { active: customers } = globalState.repo.customers || { undefined }
  const navigate = useNavigate()
  const location = useLocation()



  React.useEffect(() => {
    if (context.isUndefined(customers)) {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'get-customers'
      })
    }
  }, [
    globalActions.api,
    customers
  ])

  const onReview = () => {
    globalActions.control.mergeState({
      spotDonation: {
        customValue: context.round(globalState.spotDonation.customValue),
        value: context.round(globalState.spotDonation.value),
      }
    })
    if ((!spotDonation.value) || spotDonation.value < globalState.settings.minimumCharge) {
      globalActions.control.mergeState({
        spotDonation: {
          errors: {
            customValue: 'Minimum $' + context.formatMoney(globalState.settings.minimumCharge)
          }
        }
      })
      return
    }
    globalActions.control.mergeState({
      spotDonation: {
        confirm: true,
        errors: {}
      }
    })
  }
  const goBack = () => {
    globalActions.control.mergeState({
      spotDonation: {
        confirm: false
      }
    })
  }
  const onSubmit = () => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'make-spot-donation',
      apiPayload: {
        ...spotDonation
      },
      stateReducer: (store, response) => {
        if (response.errors && response.errors.payment_error) {
          alert(response.errors && response.errors.payment_error)
        } else {
          globalActions.control.mergeState({
            spotDonation: {
              confirm: false
            }
          })
          navigate('/donor/thankyou')
        }
        let d = new Date()
        globalActions.control.call({
          apiName: 'Events',
          apiPath: '/event',
          apiAction: 'tracking',
          apiPayload: {
            data: {
              path: location.pathname,
              notes: `Spot donation for $${spotDonation.value} to ${spotDonation.supported_organization} was successfully completed`,
              client_timestamp: d.getTime(),
              client_datetime: d.toString(),
              utc_datetime: d.toUTCString(),
            }
          }
        })
      }
    })

  }

  const onChange = (event) => {
    if (event.target.name === 'customValue') {
      globalActions.control.mergeState({
        spotDonation: {
          customValue: event.target.value,
          value: event.target.value,
          viewCustom: true,
          errors: {
            customValue: ''
          }
        }
      })
    } else {
      if (event.target.value === 'custom') {
        globalActions.control.mergeState({
          spotDonation: {
            radioValue: event.target.value,
            value: 0,
            customValue: '',
            viewCustom: true,
            errors: {
              customValue: ''
            }
          }
        })
      } else {
        globalActions.control.mergeState({
          spotDonation: {
            radioValue: event.target.value,
            value: event.target.value,
            viewCustom: false,
            errors: {
              customValue: ''
            }
          }
        })
      }
    }
  }

  const onChangeSupported = (event) => {
    globalActions.control.mergeState({
      spotDonation: {
        [event.target.name]: event.target.value,
      }
    })
  }

  if (context.isUndefined(current_user)) return null
  if (context.isUndefined(customers)) return null

  return (
    <>
      <Header label='Donation' />
      <Container
        sx={{
          backgroundColor: 'palette.background.dark',
          paddingBottom: 3,
          paddingTop: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Typography
            variant='h5'
            align='center'
            sx={{ mb: 3 }}
          >
            One-Time Donation
          </Typography>
          {spotDonation.confirm ? <>
            <Typography
              sx={{ mb: 3 }}
            >
              By pressing 'Submit Donation' you agree to donate ${context.formatMoney(globalState.spotDonation.value)} to
              your selected charity, {spotDonation.supported_organization || current_user.default_supported_customer}.
            </Typography>
            <div>
              <Button
                sx={{ mr: 1 }}
                variant='text'
                onClick={goBack}
              >
                <ArrowBackIcon />Go Back
              </Button>
              <Button onClick={onSubmit} variant='contained'> Submit Donation</Button>
            </div>

          </> : <>
            <Typography
              sx={{ mb: 3 }}
            >
              You will be charged one-time to your default funding card, and the minimum donation is ${context.formatMoney(globalState.settings.minimumCharge)}.
            </Typography>
            <form autoComplete="off">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="supported-org">Supported Organization</InputLabel>
                  <Select
                    labelId="supported-org"
                    name="supported_organization"
                    id="supported_organization"
                    value={spotDonation.supported_organization || current_user.default_supported_customer || ''}
                    label="Supported Organization"
                    onChange={onChangeSupported}
                  >
                    {customers.map(item => {
                      return <MenuItem
                        key={context.genKey(item)}
                        value={item.client_id}>
                        {item.client_name}
                      </MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Box>
            </form>
            <FormControl
              sx={{ mb: 3 }}
            >
              {/* <FormLabel id='demo-radio-buttons-group-label'>Select amount</FormLabel> */}
              <RadioGroup
                row
                aria-labelledby='demo-radio-buttons-group-label'
                value={spotDonation.radioValue || globalState.settings.minimumCharge}
                name='radioValue'
                onChange={onChange}
              >
                <FormControlLabel value='10' control={<Radio />} label='$10' />
                <FormControlLabel value='25' control={<Radio />} label='$25' />
                <FormControlLabel value='50' control={<Radio />} label='$50' />
                <FormControlLabel value='100' control={<Radio />} label='$100' />
                <FormControlLabel value='custom' control={<Radio />} label='Custom' />
                <TextField
                  label={'$' + context.formatMoney(globalState.settings.minimumCharge) + '  Minimum'}
                  variant='outlined'
                  name='customValue'
                  value={spotDonation.customValue}
                  onChange={onChange}
                  error={!!spotDonation.errors.customValue}
                  helperText={spotDonation.errors.customValue}
                  sx={{
                    display: spotDonation.viewCustom ? 'block' : 'none'
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />

              </RadioGroup>
            </FormControl>
            <div>
              <Button
                sx={{ mb: 3 }}
                variant='contained'
                onClick={onReview}
              >
                Review Donation
              </Button>
            </div>
            <Typography

              variant='caption'
            >
              Don't worry, you will have a chance to review and submit or cancel the donation on the next screen.
            </Typography>
          </>}

        </Box>
      </Container>
    </>)
}

export default Checkout