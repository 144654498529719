import * as React from 'react'
import globalHook from 'use-global-hook'
import * as actions from './actions'
import awsconfig from 'aws-exports'

const initialState = {
  notifications: {
    start: 'Friday, May 27, 2022',
    end: 'Tuesday, May 31, 2022',
    maintenance: false,
    vendorTechnicalError: false,
    serviceFees: false,
    termsOfService: false,
    privacyPolicy: false,
    toast: {},
    displayed: [],
  },
  settings: {
    minimumCharge: 10,
    defaultRecurring: 17.63
  },
  dashboard: {
    currentMonthRounding: 0,
    previousMonthRounding: 0,
    totalDonations: 0,
    avgDonations: 0,
    numDonations: 0,
  },
  controls: {
    environment: awsconfig.aws_cloud_logic_custom[0].endpoint.split('/').pop(),
    apiSpinner: {
      state: false,
      count: 0,
      title: []
    },
    message: {},
    mobileOpen: false,
    changePassword: {
      currentPassword: '',
      newPassword: '',
    },
  },
  form: {
    first_name: '',
    last_name: '',
    username: '',
    password: '',
    email_address: '',
    password_confirm: '',
    selected_option: {},
    funding_account: {},
    rounding_account: {},
    recurring_options: {},
    supported: {},
  },
  plaid: {
    token: {
      link_token: null
    }
  },
  braintree: {
    client_token: null
  },
  spotDonation: {
    value: 25,
    radioValue: 25,
    customValue: 25,
    errors: {}
  },
  repo: {}
}

// eslint-disable-next-line
const useGlobal = globalHook(React, initialState, actions)

export default useGlobal
