import React from 'react'
import Zendesk from 'react-zendesk'
const ZENDESK_KEY = 'fb5e8db0-492a-4b9a-a9a9-00da31ae874c'

// Take contact form as an example
// Let's customise our contact form appearance, launcher and add prefill content
const setting = {
  color: {
    theme: '#000'
  },
  launcher: {
    chatLabel: {
      'en-US': 'Need Help'
    }
  },
  contactForm: {
    fields: [
      { id: 'description', prefill: { '*': 'My pre-filled description' } }
    ]
  }
}



export default function Component () {
  return (
    <Zendesk
      defer
      zendeskKey={ZENDESK_KEY}
      {...setting}
    />
  )
}
