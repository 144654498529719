import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Box,
  AppBar,
  Toolbar,
  Link,
  Button,
  Grid,
  Typography
} from '@mui/material'
import { ExitToApp } from '@mui/icons-material'
import Logo from 'components/Logo'
import context from 'lib/context'


const TopBar = ({ ...rest }) => {

  return (
    <AppBar
      style={{ background: context.gradient }}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <Grid
          justifyContent='space-between' // Add it here :)
          container
        >
          <Grid item>
            <Box
              sx={{
                mt: 1
              }}>
              <RouterLink to='/'>
                <Logo style={{ maxHeight: 35 }} />
              </RouterLink>
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                mt: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>

              <Link
                sx={{ color: 'white', textDecoration: 'none' }}
                href='https://www.caringcent.com/about-us/billingquestion/'
              >
                <Typography variant='body2'>
                  Billing Questions
                </Typography>
              </Link>
              <RouterLink
                to='/register/account'
                style={{ textDecoration: 'none' }}
              >
                <Button sx={{ color: 'white', ml: 5 }}>
                  <ExitToApp sx={{ transform: 'rotate(270deg)' }} /> Sign Up
                </Button>
              </RouterLink>
              <RouterLink
                to='/donor/dashboard'
                style={{ textDecoration: 'none' }}
              >
                <Button sx={{ color: 'white', ml: 5 }}>
                  <ExitToApp sx={{ transform: 'rotate(0deg)' }} /> Sign In
                </Button>
              </RouterLink>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar >
  )
}

export default TopBar
