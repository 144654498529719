import * as React from 'react'
import useGlobal from 'store'
import { DataGrid } from '@mui/x-data-grid'
import context from 'lib/context'

function Component () {
  // eslint-disable-next-line no-unused-vars
  const [globalState, globalActions] = useGlobal()
  const [pageSize, setPageSize] = React.useState(5)

  const { rows: donationHistory } = globalState.repo.donation_history || { undefined }

  React.useEffect(() => {
    if (context.isTrue(donationHistory)) return
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-donation-history'
    })
    // eslint-disable-next-line
  }, [globalActions.api, donationHistory])


  if (!context.isTrue(donationHistory)) return null

  const columns = [
    //{ field: 'id', headerName: 'Id' },
    { field: 'effective_date', headerName: 'Date' },
    {
      field: 'amount',
      headerName: 'Amount',
      renderCell: (params) => (
        <>${context.formatMoney(params.value)}</>
      )
    },
    { field: 'status', headerName: 'Status' },
    { field: 'pmt_type', headerName: 'Pmt Type' },
    { field: 'pmt_id', headerName: 'Pmt Id' },
    { field: 'client', headerName: 'Client', width: 250 },
    { field: 'campaign', headerName: 'Campaign', width: 200 },
  ]
  return (
    <>
      <DataGrid
        rows={donationHistory}
        columns={columns}
        disableSelectionOnClick
        autoHeight
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
      />
    </>
  )
}

export default Component
