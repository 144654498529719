/*******************************************************************
 * imports
 *   imports in all files should follow the order:
 *     non-mui imports
 *     mui imports
 *     local files
 *
 *
 *******************************************************************/
import * as React from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import useGlobal from 'store'
import context from 'lib/context'
import { Notifier, ZenDesk, Copyright } from 'components'
import {
  Box,
  useTheme,
  useMediaQuery,
  Paper,
  Typography,
} from '@mui/material'

import Navigator from './Navigator'
import { SnackbarProvider } from 'notistack'


const drawerWidth = 256

function Component () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { notifications } = globalState
  const { mobileOpen } = globalState.controls
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const navigate = useNavigate()
  const location = useLocation()
  const { current_user } = globalState.repo || undefined

  React.useEffect(() => {
    if (notifications.maintenance) {
      navigate('/notifications/maintenance')
    }
  })

  React.useEffect(() => {
    globalActions.control.subscribe()
    return globalActions.control.unsubscribe
  }, [globalActions.control])

  React.useEffect(() => {
    if (context.isUndefined(current_user)) {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'get-user-data'
      })
    }
  }, [
    globalActions.api,
    current_user
  ])

  React.useEffect(() => {
    if (context.isDefined(current_user)) {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'get-client-data',
        apiPayload: {
          client: current_user.default_supported_customer
        }
      })
    }
  }, [
    globalActions.api,
    current_user
  ])

  React.useEffect(() => {
    if (context.isDefined(current_user)) {
      let d = new Date()
      globalActions.control.call({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'tracking',
        apiPayload: {
          data: {
            path: location.pathname,
            notes: 'Initial page load',
            client_timestamp: d.getTime(),
            client_datetime: d.toString(),
            utc_datetime: d.toUTCString(),
          }
        }
      })
    }
  }, [
    location,
    current_user,
    globalActions.control
  ])

  if (context.isUndefined(current_user)) return

  return (
    <>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <Box
          component='nav'
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {isSmUp ?
            <Navigator
              PaperProps={{
                style: { width: drawerWidth }
              }}
              sx={{
                display: { sm: 'block', xs: 'none' }
              }}
            /> : (
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant='temporary'
                open={mobileOpen}
                onClose={globalActions.control.toggleNavigator}
              />
            )}
        </Box>
        <Box name="outlet-container" sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}>
          <SnackbarProvider maxSnack={3}>
            <Notifier />
            <ZenDesk />
            <Outlet />
          </SnackbarProvider>
        </Box>
      </Box>
    </>
  )
}
const components = {
  Header () {
    return (
      <Box textAlign="center" >
        <Paper elevation={3} sx={{ p: 5, m: 5 }}>
          <img
            alt="Caringcent Logo"
            src="https://caringcent.org/static/media/CC.017074a8deee366dd490.jpg"
          />
          <Typography
            sx={{ mt: 1 }}
            variant='h3'
          >
            Donors
          </Typography>
          <Typography
            sx={{
              mt: 5,
              color: 'orange',
            }}
            variant='h4'>
            Notice
          </Typography>
          <Typography sx={{
            mt: 1,
            textAlign: 'justify',
            fontSize: '90%'
          }}>
            If you haven't signed in to your account for a while,
            for security purposes you will have to reset your password.
            If your password is not working, please click "Forgot your password?"
          </Typography>
        </Paper>
      </Box >
    )
  },

  Footer () {
    return (
      <Box textAlign="center" sx={{ p: 5, m: 5 }}>
        <Copyright />
      </Box>
    )
  },
}

const formFields = {
  signIn: {
    username: {
      label: 'Email Address',
      placeholder: 'Enter your email address',
      order: 1
    },
    password: {
      label: 'Password: (min 8 characters)',
      placeholder: 'Enter your password',
      order: 2,
    },
  },
}

export default withAuthenticator(Component, {
  usernameAttributes: 'email',
  signUpConfig: {
    usernameAlias: 'email'
  },
  signInConfig: {
    usernameAlias: 'email'
  },
  hideSignUp: true,
  components,
  formFields
})