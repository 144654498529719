import _ from 'underscore'
import * as React from 'react'
import context from 'lib/context'
import useGlobal from 'store'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@mui/material'

export default function Component ({ row, idx, sx }) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { current_user } = globalState.repo || { undefined }
  const { rows: swipegiveSettings } = globalState.repo.swipegive_settings || { undefined }
  const { active: customers } = globalState.repo.customers || { undefined }
  React.useEffect(() => {
    if (context.isTrue(customers)) return
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-customers'
    })
  }, [
    globalActions.api,
    customers
  ])

  const sendUpdate = _.debounce((name, value) => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'update-swipe-give-settings',
      apiPayload: {
        sys_id: row.sys_id,
        name,
        value
      },
    })
  }, 1000)

  const handleDeleteRecurring = () => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'delete-swipe-give-settings',
      apiPayload: {
        sys_id: row.sys_id
      },
    })
  }

  const handleChange = (event) => {
    let { name, value, checked, type } = event.target
    if (type === 'checkbox') {
      value = checked
    }
    const rows = swipegiveSettings
    rows[idx][name] = value
    globalActions.control.mergeState({
      repo: {
        swipegiveSettings: {
          rows
        }
      }
    })
    sendUpdate(name, value)
  }
  if (context.isUndefined(current_user)) return null
  if (context.isUndefined(customers)) return null
  return (
    <Card sx={sx}>
      <CardContent>

        <form autoComplete="off">
          <Box sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}>
            <FormControl fullWidth sx={{
              pb: 1
            }}>
              <InputLabel id="supported-org">Supported Organization</InputLabel>
              <Select
                labelId="supported-org"
                name="client_id"
                id="client_id"
                value={row.client_id || ''}
                label="Supported Organization"
                onChange={handleChange}
              >
                <MenuItem value=''>
                  Support My Default Organization
                </MenuItem>
                {customers.map(item => {
                  return <MenuItem
                    key={context.genKey(item)}
                    value={item.client_id}>
                    {item.client_name}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
            {/*
            This is a user  level field
             <TextField
              name="max_donation_amount"
              label="Maximum Monthly Donation"
              value={row.max_donation_amount}
              onChange={handleChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <Typography variant='caption'>
              What should we do if a transaction ends in .00?
            </Typography> */}
            <Box>
              <Switch
                name="should_round_zero_up"
                checked={row.should_round_zero_up}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography variant='caption'>
                {row.should_round_zero_up ? 'Round .00 up to $1' : 'Round .00 down to $0'}
              </Typography>
            </Box>
          </Box>
        </form>

      </CardContent>
      <CardActions>
        <Button size="small" color='secondary' onClick={handleDeleteRecurring}>Delete</Button>
      </CardActions>
    </Card>
  )
}