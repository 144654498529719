import * as React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export default function FormDialog ({
  message,
  title,
  label,
  defaultValue,
  saveLabel,
  cancelLabel,
  onSave = () => { },
  onCancel = () => { }
}) {
  const [open, setOpen] = React.useState(true)
  const [newId, setNewId] = React.useState(defaultValue)

  const onChange = (event) => {
    setNewId(event.target.value)
  }

  const handleCancel = () => {
    setOpen(false)
    onCancel(newId)
  }

  const handleSave = () => {
    setOpen(false)
    onSave(newId)
  }

  return (
    <div>
      <Dialog open={open} onClose={handleCancel} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'> {title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            label={label}
            onChange={onChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color='primary'>
            {cancelLabel}
          </Button>
          <Button onClick={handleSave} color='primary'>
            {saveLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
