import _ from 'underscore'
import * as React from 'react'
import context from 'lib/context'
import useGlobal from 'store'

import {
  Box,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@mui/material'

export default function Component ({ metric, idx, sx }) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { current_user } = globalState.repo || { undefined }
  const { lookup: customers } = globalState.repo.customers || { undefined }
  const { rows: rally_metrics } = globalState.repo.rally_metrics || { undefined }

  React.useEffect(() => {
    if (context.isTrue(customers)) return
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-customers'
    })
  }, [
    globalActions.api,
    customers
  ])

  const sendUpdate = _.debounce((name, value) => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'update-rally-metrics',
      apiPayload: {
        sys_id: metric.sys_id,
        name,
        value
      },
    })
  }, 1000)


  const handleChange = (event) => {
    let { name, value, checked, type } = event.target
    if (type === 'checkbox') {
      value = checked
    }
    rally_metrics[idx][name] = value
    globalActions.control.mergeState({
      repo: {
        rally_metrics: {
          rows: rally_metrics
        }
      }
    })
    sendUpdate(name, value)
  }
  if (context.isUndefined(current_user)) return null
  if (context.isUndefined(customers)) return null
  return (
    <Card sx={sx}>
      <CardContent>

        <form autoComplete="off">
          <Box sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
          }}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                {metric.label || metric.text}
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name="value"
                  label="Metric Value"
                  value={metric.value}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Switch
                  name="selected"
                  checked={metric.selected}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography variant='caption'>
                  {metric.selected ? 'On - Bill this metric monthly' : 'Off - Do not bill this metric monthly'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </form>
      </CardContent>

    </Card>
  )
}