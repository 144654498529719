import { API, Auth } from 'aws-amplify'
import awsconfig from 'aws-exports'
import context from 'lib/context'
import { md5 } from 'lib/md5'

const __API__ = {
  Events: awsconfig.aws_cloud_logic_custom[0].name,
  Public: awsconfig.aws_cloud_logic_custom[1].name
}

export const getApiIdentifier = (name) => {
  return __API__[name]
}
const running = {}
export const performApi = async (store, {
  apiName,
  apiPath,
  apiAction,
  apiPayload = {},
  callback = (store, response) => { },
  stateReducer = (store, response) => {
    return response
  },
  handleResponse = (store, response) => {
    return store.actions.control.handleResponse(response)
  },
  spinner
}) => {
  const hash = md5(JSON.stringify(apiPayload))
  const runningKey = apiAction + '-' + hash
  context.log(`${apiName}  ${apiPath}  ${apiAction}\t\t\t(${hash})`)
  if (running[runningKey]) {
    context.log('Skip repeated action: ' + apiAction)
    return
  }
  running[runningKey] = true
  if (context.isObject(apiPayload)) {
    apiPayload.cognito_user = Auth.user
  }
  const init = {
    body: {
      env: context.environment,
      action: apiAction,
      version: 'v1',
      ts: new Date().toISOString(),
      payload: apiPayload
    }
  }

  try {
    const request = API.post(getApiIdentifier(apiName), apiPath + '?action=' + apiAction, init)
    request._apiPath = apiPath
    request._body = init.body
    request._cancel = (event) => {
      API.cancel(request, 'Cancel Pressed')
    }

    if (spinner) {
      store.actions.views.spinUp(spinner.content || 'Please Wait...', request)
    }
    const response = await request
    // Callback does not return as part of the result chain.
    // use this to perform one-off action on the the response
    // or after the api call.
    await callback(store, response)
    // stateReducer should alter the response as necessary.
    const result = await stateReducer(store, response)
    // handleResponse is where we take pre-defined action based on the contract
    // with the api.
    const final = await handleResponse(store, result)
    if (context.isTrue(final)) {
      store.actions.control.mergeState(final, true)
    }
    if (spinner) {
      store.actions.views.spinDown()
    }
    running[runningKey] = false
  } catch (error) {
    running[runningKey] = false
    if (spinner) {
      store.actions.views.spinDown()
    }
    if (API.isCancel(error)) {
      context.log(error.message)
    } else if (error && error.response && error.response.data && error.response.data.python_exception) {
      context.handlePythonException(error.response.data.python_exception)
    } else if (error && error.response && error.response.data && error.response.data.message) {
      context.handleApiError(error.response.data)
      context.log(error)
    } else {
      console.error('Unhandled Error from API call')
      context.log(error)
      throw error
    }
  }
}
