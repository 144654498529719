import * as React from 'react'
import useGlobal from 'store'
import {
  Alert,
  FormLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem
} from '@mui/material'

export const Component = (props) => {
  const [globalState, globalActions] = useGlobal()
  const { page = {} } = globalState
  const { form = {}, errors = {} } = page

  const id = props.id || props.name
  const name = props.name || props.id

  function onChange (event) {
    let { name, value, checked, type } = event.target
    if (type === 'checkbox') {
      value = checked
    }
    globalActions.control.setForm({
      formName: 'page',
      type: 'set',
      name: name,
      value: value
    })
  }

  return (
    <>
      <span error-id={name} />
      <FormControl
        fullWidth
      >
        <FormLabel component='legend'>
          {props.label}    {
            props.required &&
            <span style={{ color: 'red', fontSize: '.8em' }}>* Required</span>
          }
        </FormLabel>

        <Select
          id={id}
          name={name}
          value={form[name] || ''}
          onChange={onChange}
          fullWidth
          error={Boolean(errors[name])}
        >
          {
            Object.entries(props.values).map(([key, val]) => {
              return <MenuItem key={key} value={key}>{val}</MenuItem>
            })
          }
        </Select>
        {props.helperText &&
          <FormHelperText>{props.helperText}</FormHelperText>}
        {errors[name] &&
          <Alert severity='error'>{errors[name]}</Alert>}
      </FormControl>
    </>
  )
}

export default Component
