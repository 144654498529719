import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { usePlaidLink } from 'react-plaid-link'
import useGlobal from 'store'
import context from 'lib/context'

export default function PlaidLink () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { link_token } = globalState.plaid && globalState.plaid.token
  const { email_address } = globalState.form

  // eslint-disable-next-line
  const { open, ready, error, exit } = usePlaidLink({
    token: link_token,
    onEvent: (eventName, metadata) => {
      globalActions.api.performApi({
        apiName: 'Public',
        apiPath: '/public',
        apiAction: 'track-plaid-event',
        apiPayload: {
          eventName,
          metadata
        }
      })
    },
    onExit: (error, metadata) => { },
    onLoad: () => { },
    onSuccess: (public_token, metadata) => {
      // send public_token to server

      globalActions.control.mergeState({
        form: {
          rounding_account: {
            public_token,
            metadata
          }
        }
      })
    },
  })

  React.useEffect(() => {
    if (email_address) {
      if (!link_token) {
        globalActions.api.performApi({
          apiName: 'Public',
          apiPath: '/public',
          apiAction: 'get-plaid-token',
          apiPayload: {
            form: globalState.form
          }
        })
      }
    }
    // eslint-disable-next-line
  }, [globalActions.api, link_token, email_address])

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <button onClick={() => open()} disabled={!ready}>
          Connect a credit card account
        </button>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant='h3'>
          Set Up Monthly Recurring Donation
        </Typography>
        <Typography variant='h5'>
          Manage Monthly Recurring Donation Settings
        </Typography>
        <Typography variant='body1'>
          Your funding credit card will be used for this monthly donation. You will be charged once a month on our billing cycle, and it will continue until you turn your recurring donation off on this page. The minimum recurring donation is ${context.formatMoney(globalState.settings.minimumCharge)}.
        </Typography>
      </Box>
    </Container >
  )
}