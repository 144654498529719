import * as React from 'react'
import { Paper, Typography } from '@mui/material'
import ParallaxScroll from 'components/Parallax/Scroll'
import { PublicTopBar, PublicFooter } from 'components'
import useGlobal from 'store'
import context from 'lib/context'


const Component = () => {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  globalActions.control.beforeLayout()
  window.scrollTo(0, 0)
  React.useEffect(() => {
    globalActions.control.signOut()
    globalActions.control.setState({ result: null })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <PublicTopBar />
      <ParallaxScroll
        backgroundImage={context.imageUrl('/cc/CCWebImage1-Main.jpg')}
        minHeight='100vh'
      >
        <Paper sx={{
          height: '100%',
          p: 3,
          backgroundColor: 'primary.dark',
          width: '80%',
          ml: 'auto',
          mr: 'auto',
          mt: '20vh',
          textAlign: 'center',
          maxWidth: '750px'
        }}>
          <Typography sx={{
            p: 2,
            m: 2,
            color: 'primary.light',
            opacity: 1,
            fontWeight: 700,
            fontSize: '32px'
          }}>
            Registration Successful
          </Typography>

          <Typography sx={{
            p: 2,
            m: 2,
            color: 'primary.contrastText',
            opacity: 1,
            fontWeight: 500,
            fontSize: '18px'
          }}>
            An email with a one-time password has been sent to your email. You must respond within 1 hour to continue registration.
            If you do not see the email in your inbox, please check your spam folder.
          </Typography>
          <Typography sx={{
            m: 2,
            color: 'primary.contrastText',
            opacity: 1,
            fontWeight: 500,
            fontSize: '23px'
          }}>
            Once you have received the email, you may sign in with the one time password provided.
          </Typography>

        </Paper>
      </ParallaxScroll>
      <PublicFooter
        style={{ background: context.gradient }}
        sx={{
          pt: 3,
          pb: 3,
        }}
      />
    </>
  )
}

export default Component
