import * as React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import useGlobal from 'store'
import context from 'lib/context'
import { useNavigate } from 'react-router-dom'



export default function Component () {
  // eslint-disable-next-line no-unused-vars
  const [globalState, globalActions] = useGlobal()
  const navigate = useNavigate()
  const {
    showChecklist
  } = globalState.controls

  const { current_user } = globalState.repo || { undefined }
  const { current_client } = globalState.repo || { undefined }

  const { rows: paymentProfiles } = globalState.repo.payment_profiles || { undefined }
  const { rows: plaidAccounts } = globalState.repo.plaid_accounts || { undefined }


  React.useEffect(() => {
    if (current_user.status !== 'active') {
      globalActions.control.mergeState({
        controls: {
          showChecklist: false
        }
      })
      return
    }
    if (
      (context.isDefined(current_user) && context.isUndefined(current_user.full_name)) ||
      (context.isDefined(current_user) && context.isUndefined(current_user.default_supported_customer)) ||
      (context.isDefined(paymentProfiles) && context.isEmpty(paymentProfiles))
    ) {
      globalActions.control.mergeState({
        controls: {
          showChecklist: true
        }
      })
      return
    } else {
      globalActions.control.mergeState({
        controls: {
          showChecklist: false
        }
      })
      return
    }
  }, [
    current_user,
    paymentProfiles,
    plaidAccounts,
    globalActions.control,
    showChecklist
  ])

  if (!showChecklist) return null

  return (
    <>
      <Box sx={{
        minWidth: 275,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Card
          variant="outlined"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  color="text.secondary"
                  gutterBottom
                  variant="h6">
                  Registration Checklist
                </Typography>
                <Typography
                  color="text.secondary"
                  gutterBottom
                  variant="body">
                  Welcome to CaringCent! Your account set-up is not complete. You will not be
                  able to make recurring donations to your selected organization until you
                  complete the setup of your account. Please click on the lines below that are
                  not checked to complete setup of your account.
                </Typography>
              </Grid >
              <Grid item xs={12}>
                <FormGroup>
                  <Grid container>
                    {(current_user.full_name) ? <>
                      <Grid item xs={10}>
                        <FormControlLabel control={<Checkbox
                          defaultChecked
                          checked
                          disabled />}
                          label="Add your Name" />
                      </Grid>
                    </> : <>
                      <Grid item xs={10}>
                        <FormControlLabel control={<Checkbox
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            navigate('/donor/my-profile')
                          }}
                        />}
                          label="Add your Name" />
                      </Grid>
                    </>
                    }
                    {current_user.default_supported_customer ? <>
                      <Grid item xs={10}>
                        <FormControlLabel control={<Checkbox
                          defaultChecked
                          checked
                          disabled />}
                          label="Select an organization to support" />
                      </Grid>
                    </> : <>
                      <Grid item xs={10}>
                        <FormControlLabel control={<Checkbox
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            navigate('/donor/my-profile')
                          }} />}
                          label="Select an organization to support" />
                      </Grid>
                    </>
                    }
                    {context.isTrue(paymentProfiles) ? <>
                      <Grid item xs={10}>
                        <FormControlLabel control={<Checkbox
                          defaultChecked
                          checked
                          disabled />}
                          label="Add funding card for donations" />
                      </Grid>
                    </> : <>
                      <Grid item xs={10}>
                        <FormControlLabel
                          control={<Checkbox
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              navigate('/donor/funding-account')
                            }} />}
                          label="Add a funding card for donations" />
                      </Grid>
                    </>
                    }
                    {context.isDefined(current_client) && current_client.allow_product_swipegive &&
                      <>
                        {context.isTrue(plaidAccounts) ? <>
                          <Grid item xs={10}>
                            <FormControlLabel control={<Checkbox
                              defaultChecked
                              checked
                              disabled />}
                              label="Add a rounding account (optional)" />
                          </Grid>
                        </> : <>
                          <Grid item xs={10}>
                            <FormControlLabel control={<Checkbox
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                navigate('/donor/rounding-account')
                              }} />}
                              label="Add a rounding account (optional)" />
                          </Grid>
                        </>}
                      </>
                    }
                    {context.isDefined(current_client) && current_client.allow_product_swipegive &&
                      <>
                        <Grid item xs={10}>
                          <FormControlLabel control={<Checkbox
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              navigate('/donor/recurring-settings')
                            }} />}
                            label="Set up monthly recurring donation options (optional)" />
                        </Grid>
                      </>
                    }
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button size='small' onClick={() => {
              globalActions.control.mergeState({
                controls: {
                  showChecklist: false
                }
              })
            }}>
              Dismiss this checklist
            </Button>
          </CardActions>
        </Card>
      </Box >
    </>
  )
}
