import * as React from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import TabPanel from 'components/TabPanel'
import DonationHistory from './DonationHistory'
import TaxReports from './TaxReports'

import Header from 'components/Header'

const tabs = [
  { idx: 0, label: 'Donation History', Component: DonationHistory },
  { idx: 1, label: 'Tax Reports', Component: TaxReports },
]

function TabView() {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Header label='Reports' />
      <Container
        sx={{
          paddingBottom: 3,
          paddingTop: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              <Tabs
                onChange={handleChange}
                value={value}
                textColor='inherit'
                variant='scrollable'
                scrollButtons='auto'
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#D97D54'
                  }
                }}
              >
                {tabs.map(({ label, idx }) => {
                  return <Tab key={idx} label={label} />
                })}
              </Tabs>
              {tabs.map(({ idx, Component }) => {
                return (
                  <TabPanel
                    key={idx}
                    value={value}
                    index={idx}
                  >
                    <Component />
                  </TabPanel>
                )
              })}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export default TabView