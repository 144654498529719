import Box from '@mui/material/Box'

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="main" sx={{ flex: 1, pt: 3 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

export default TabPanel