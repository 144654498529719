import * as React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import useGlobal from 'store'
import context from 'lib/context'

const defaultDescription = `
  <p> Did you know that the average consumer donates more than $10.00 a month in checkout counter change to charities promoted by merchants? What if you could choose the nonprofit that receives this donation...and track the tax deduction? Now you can!</p>
  <p> With CaringCent, supporting your nonprofit organization is easy, fun, and affordable. We believe in "Your Change, Your Choice!" You can now register your credit or debit card with CaringCent and when you shop, your purchase total will be rounded up to the nearest dollar. The difference is then donated to support the cause you care about!</p>
  <p> With CaringCent, you can:</p>
  <ol>
    <li> Track and manage your monthly donation, including setting a monthly maximum contribution</li>
    <li> Retain the tax benefit - CaringCent keeps track of your donations and provides receipts</li>
    <li> Affordably support your favorite nonprofit!</li>
  </ol>
`

export default function SignIn () {
  const [display, setDisplay] = React.useState(false)

  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  let { vanityurl } = useParams()
  console.log('here')
  const {
    supported
  } = globalState

  React.useEffect(() => {
    globalActions.control.signOut()
  }, []) // eslint-disable-line

  console.log(vanityurl)
  React.useEffect(() => {
    if (context.isTrue(supported)) return
    if (!context.isTrue(vanityurl)) return
    globalActions.api.performApi({
      apiName: 'Public',
      apiPath: '/public',
      apiAction: 'get-vanity-data',
      apiPayload: {
        vanityurl: vanityurl
      },
      callback: (store, response) => {
        setDisplay(true)
      },
    })
  }, [
    supported,
    vanityurl,
    globalActions.api,
    globalState.supported,
  ])
  if (!display) return
  if (context.isUndefined(vanityurl)) return <>No URL provided</>

  if (context.isUndefined(supported) || context.isTrue(supported.inactive)) {
    return (
      <Box sx={{
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
      }}>
        <Typography variant='h2' sx={{}}>
          Either the URL you are trying to reach is not valid or the organization
          you are supporting is not active in our system for registration.
        </Typography>
        <br />
        <Typography variant='h3' sx={{}}>
          Please check that you are going to the currect URL or contact customer
          service if you feel this is an error.
        </Typography>
        <br />
        <Typography variant='h4' sx={{}}>
          support@caringcent.com
        </Typography>
      </Box>)
  }

  const title = supported.campaign_title || supported.page_title || supported.client_name
  const short_description = supported.short_description
  const long_description = supported.long_description || defaultDescription

  return (

    <Box sx={{
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
    }}>
      {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
      {short_description && <h4 dangerouslySetInnerHTML={{ __html: short_description }} />}
      {supported.large_logo_url && <img
        alt='Organization Logo'
        style={{
          width: '80%',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        }}
        src={context.imageUrl(supported.large_logo_url)} />}
      <div dangerouslySetInnerHTML={{ __html: long_description }} />

    </Box>

  )
}