import * as React from 'react'
import useGlobal from 'store'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Header from 'components/Header'
import { Auth } from 'aws-amplify'
import context from 'lib/context'
import PasswordField from 'components/PasswordField'
import TermsOfService from 'views/Legal/TermsOfServicePopup'
import PrivacyPolicy from 'views/Legal/PrivacyPolicyPopup'
import debounce from 'lodash.debounce'
import { useLocation } from 'react-router-dom'

function Component () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { changePassword } = globalState.controls
  const { current_user } = globalState.repo || { undefined }
  const { active: customers } = globalState.repo.customers || { undefined }

  const location = useLocation()

  React.useEffect(() => {
    if (context.isTrue(customers)) return
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-customers'
    })
  }, [
    globalActions.api,
    customers
  ])

  // eslint-disable-next-line
  const sendUpdate = React.useCallback(debounce((name, value) => {
    value = value.trim()
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'user-update',
      apiPayload: {
        name,
        value
      },
    })
  }, 1250), [])

  React.useEffect(() => {
    return () => {
      sendUpdate.cancel()
    }
  }, [sendUpdate])

  const handleChange = (event) => {
    let name = event.target.name
    let value = event.target.value
    globalActions.control.mergeState({
      repo: {
        current_user: {
          [name]: value
        }
      }
    })
    sendUpdate(name, value)
  }


  const handlePass = (event) => {
    let name = event.target.name
    let value = event.target.value
    globalActions.control.mergeState({
      controls: {
        changePassword: {
          [name]: value,
          error: ''
        }
      }
    })
  }



  if (context.isUndefined(current_user)) return null
  if (context.isUndefined(customers)) return null
  return <>
    <Header label='My Profile' title='My Profile' />
    <Container maxWidth={false} sx={{ pt: 3 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid container>
            <Grid item>
              <Typography>Email Address</Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Box sx={{
                marginRight: 2
              }}>
                <Typography>{Auth.user.attributes.email}</Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>You may not change your email address at this time. .</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid container>
            <Grid item>
              <Typography>Full Name</Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Box sx={{
                marginRight: 2
              }}>
                {(!current_user.full_name) ? <>
                  <Typography sx={{ color: 'red' }}>
                    You must enter your full name.
                  </Typography>
                </> : <>
                  <Typography>{current_user.full_name}</Typography>
                </>}
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <form autoComplete="off">
            <TextField
              name="full_name"
              label="Full Name"
              value={current_user.full_name || ''}
              onChange={handleChange}
              title='full_name'
              autoComplete="alskdfjiaouwjem"
            />
          </form>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid container>
            <Grid item>
              <Typography>Supported Organization</Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Box sx={{
                marginRight: 2
              }}>
                {(!current_user.default_supported_customer) ? <>
                  <Typography sx={{ color: 'red' }}>
                    Please choose an organization to support.
                  </Typography>
                </> : <>
                  <Typography>
                    <Box sx={{
                      textTransform: 'capitalize'
                    }}>
                      {current_user.default_supported_customer}
                    </Box>
                  </Typography>
                </>}
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ mb: 2 }}>
            The organization you select here becomes the default for future donations, but
            you always have the option to change it to organization or campaign you wish.
          </Typography>
          <form autoComplete="off">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="supported-org">Supported Organization</InputLabel>
                <Select
                  labelId="supported-org"
                  name="default_supported_customer"
                  id="default_supported_customer"
                  value={current_user.default_supported_customer || ''}
                  label="Default Supported Organization"
                  onChange={handleChange}
                >
                  {customers.map(item => {
                    return <MenuItem
                      key={context.genKey(item)}
                      value={item.client_id}>
                      {item.client_name}
                    </MenuItem>
                  })}
                </Select>
              </FormControl>
            </Box>
            {/* <TextField
              name="default_supported_customer"
              label="Default Supported Organization"
              value={current_user.default_supported_customer || ''}
              onChange={handleChange}
              title='default_supported_customer'
              autoComplete="alskdfjiaouwjem"
            /> */}
          </form>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid container>
            <Grid item>
              Update Password
            </Grid>
            <Grid item xs />
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <form autoComplete="off">
            <Stack
              alignItems="center"
              sx={{
                '& > :not(style)': { m: 1 }
              }}
            >
              {changePassword.error &&
                <Box
                  id='password-error'
                  sx={{
                    width: '100%',
                    color: 'red',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  {changePassword.error}
                </Box>
              }
              <PasswordField
                name="currentPassword"
                label="Current Password"
                type='password'
                value={changePassword.currentPassword || ''}
                onChange={handlePass}
              />
              <PasswordField
                name="newPassword"
                label="New Password"
                type='password'
                value={changePassword.newPassword || ''}
                onChange={handlePass}
              />
              <Button
                color='primary'
                fullWidth
                variant='text'
                onClick={() => {
                  Auth.currentAuthenticatedUser()
                    .then(user => {
                      return Auth.changePassword(user, changePassword.currentPassword, changePassword.newPassword)
                    })
                    .then(data => {
                      globalActions.control.mergeState({
                        controls: {
                          changePassword: {
                            currentPassword: '',
                            newPassword: '',
                            error: 'Password change success'
                          }
                        }
                      })
                      let d = new Date()
                      globalActions.control.call({
                        apiName: 'Events',
                        apiPath: '/event',
                        apiAction: 'tracking',
                        apiPayload: {
                          data: {
                            path: location.pathname,
                            notes: 'Password changed by user.',
                            client_timestamp: d.getTime(),
                            client_datetime: d.toString(),
                            utc_datetime: d.toUTCString(),
                          }
                        }
                      })
                    })
                    .catch(err => {
                      globalActions.control.mergeState({
                        controls: {
                          changePassword: {
                            error: err.message
                          }
                        }
                      })
                      context.animateScrollToName('password-error')
                    })
                }}
              >
                Submit New Password
              </Button>
            </Stack>
          </form>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid container>
            <Grid item>
              <Typography>Account Status</Typography>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Box sx={{
                marginRight: 2
              }}>
                <Typography>{current_user.status}</Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{ padding: 3 }}
          >
            {current_user.status === 'suspended' &&
              <>You may leave your account in
                a suspended state for as long as you wish, but when you re-activate
                you must ensure your payment card is not expired and rounding accounts
                are active and up-to-date. Please double check your settings.</>
            }
          </Box>
          <Grid container spacing={3}>
            {current_user.status === 'active' &&
              <Grid item xs={12}>
                <Button
                  sx={{ width: 300 }}
                  variant='outlined'
                  onClick={async () => {
                    try {
                      await context.okCancelPopup(
                        <>
                          <Box sx={{ fontWeight: 'bold', color: 'black' }}>
                            Suspending your account will prevent future donations, and your supported organization will not
                            receive any further support until your account is reactivated. Account suspension is temporary and does not delete any financial account tokens.
                          </Box>
                          <Box sx={{ fontWeight: 'bold', color: 'black', mt: 2 }}>
                            You may activate your account at any time
                            by signing in to your account and clicking the "Activate Account" button.
                          </Box>
                          <Box sx={{ fontWeight: 'bold', color: 'red', fontSize: '80%', mt: 1 }}>
                            If you want to permenantly close your account and have your financial tokens deleted from our system, please click "Close Account".
                          </Box>
                        </>,
                        'Confirm Suspend Account',
                        'Suspend'
                      )
                    } catch {
                      return
                    }
                    globalActions.control.call({
                      apiName: 'Events',
                      apiPath: '/event',
                      apiAction: 'suspend-account',
                    })
                  }}
                >
                  Suspend Account
                </Button>
                <Box
                  sx={{ padding: 3 }}
                >
                  Suspend your account to temporarily stop contributing to your
                  selected organization. Suspending your account does not delete any
                  of your accounts or data, and allows you to easily activate your
                  account again in the future when you want to begin contributing
                  to your selected organization(s) again. You may leave your account in
                  a suspended state for as long as you wish, but when you re-activate
                  you must ensure your payment card and rounding account are active
                  and up-to-date.
                </Box>
              </Grid>
            }

            {current_user.status !== 'active' &&
              <Grid item xs={12}>
                <Button
                  sx={{ width: 300 }}
                  variant='outlined'
                  onClick={() => {
                    globalActions.control.call({
                      apiName: 'Events',
                      apiPath: '/event',
                      apiAction: 'activate-account',
                    })
                  }}
                >
                  Activate Account
                </Button>
                <Box
                  sx={{ padding: 3 }}
                >
                  Activate your account to begin contributing to your
                  selected organization(s). Please ensure that your
                  payment profile is active (not expired) and your
                  rounding accounts are in good standing, if you
                  are rounding for your supported organization.
                </Box>

              </Grid>
            }
            {current_user.status !== 'closed' &&
              <Grid item xs={12}>
                <Button
                  sx={{ width: 300 }}
                  variant='outlined'
                  onClick={async () => {
                    try {
                      await context.okCancelPopup(
                        <>
                          <Box sx={{ fontWeight: 'bold', color: 'black' }}>
                            Closing your account will prevent future donations, and your supported organization will not
                            receive any further support from you. Account closure is permenant and all financial account tokens
                            are deleted. If you are supporting multiple campaigns under different email addresses,
                            you may need to close additional accounts. Contact customer support if you need assistance.
                          </Box>
                          <Box sx={{ fontWeight: 'bold', color: 'black', mt: 2 }}>
                            By law we must retain some financial transaction data for the period of time.
                            Please see our Terms of Service for more information regarding data retention.
                          </Box>
                          <Box sx={{ fontWeight: 'bold', color: 'black', mt: 2 }}>
                            Although your account is closed, you may continue to sign in to retreive financial transaction
                            reports for tax purposes.
                          </Box>
                        </>,
                        'Confirm Close Account',
                        'Close Account'
                      )
                    } catch {
                      return
                    }
                    globalActions.control.call({
                      apiName: 'Events',
                      apiPath: '/event',
                      apiAction: 'close-account',
                    })
                  }}
                >
                  Close Account
                </Button>
                <Box
                  sx={{ padding: 3 }}
                >
                  Closing your account deletes your CaringCent profile so you will
                  no longer be able to sign in. Closing your account also
                  deletes your stored credit card tokens, rounding accounts, and
                  non-essential data associated with your account.
                  There is some data we are required to retain by law. Please see
                  our <TermsOfService label={{
                    display: 'inline', textDecoration: 'underline'
                  }} /> and <PrivacyPolicy label={{
                    display: 'inline', textDecoration: 'underline'
                  }} /> for more information.

                </Box>
              </Grid>
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container >
  </>
}

export default Component
