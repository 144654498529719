import { createTheme } from '@mui/material'
import shadows from './shadows'
import typography from './typography'
import { CustomFontDefs } from 'theme/fonts'


let theme = createTheme({
  palette: {
    primary: {
      light: '#5fd1f8',
      main: '#0da0c5',
      dark: '#007194',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ffca46',
      main: '#e09900',
      dark: '#a96b00',
      contrastText: '#ffffff'
    },
  },
  shadows,
  typography
})

theme = {
  ...theme,
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': CustomFontDefs
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          p: 1,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#4fc3f7',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          mr: 2,
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          margin: '0 !important',
          padding: '0 !important',
        }
      }
    },
    MUIDataTable: {
      styleOverrides: {
        // paper: {
        //   height: 'inherit',
        // },
        // responsiveScroll: {
        //   maxHeight: 'none',
        //   height: 'calc(100% - 128px)'
        // }
      }
    }
  }
}

export default theme
