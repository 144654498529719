import * as React from 'react'
import context from 'lib/context'
const Logo = (props) => {
  return (
    <img
      alt='Logo'
      src={context.imageUrl('/cc/img/CaringCent_DonateStrip_KO_FIN.png')}
      {...props}
    />
  )
}

export default Logo
