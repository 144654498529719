import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import useGlobal from 'store'
export default function SignIn () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Create Account
        </Typography>
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='first_name'
            label='First Name'
            name='first_name'
            autoComplete='first_name'
            value={globalState.form.first_name}
            onChange={(event) => {
              globalActions.control.mergeState({ 'form': { 'first_name': event.target.value } })
            }}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            id='last_name'
            label='Last Name'
            name='last_name'
            autoComplete='last_name'
            value={globalState.form.last_name}
            onChange={(event) => {
              globalActions.control.mergeState({ 'form': { 'last_name': event.target.value } })
            }}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            id='email_address'
            label='Email Address'
            name='email_address'
            autoComplete='email_address'
            value={globalState.form.email_address}
            onChange={(event) => {
              globalActions.control.mergeState({ 'form': { 'email_address': event.target.value } })
            }}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            value={globalState.form.password}
            onChange={(event) => {
              globalActions.control.mergeState({ 'form': { 'password': event.target.value } })
            }}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password_confirm'
            label='Password Confirm'
            type='password'
            id='password_confirm'
            autoComplete='password-confirm'
            value={globalState.form.password_confirm}
            onChange={(event) => {
              globalActions.control.mergeState({ 'form': { 'password_confirm': event.target.value } })
            }}
          />
        </Box>
      </Box>
    </Container>
  )
}