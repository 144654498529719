import * as React from 'react'
import useGlobal from 'store'
import context from 'lib/context'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Skeleton,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

export function OtherTable () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { rows: plaidAccounts } = globalState.repo.plaid_accounts || { undefined }

  React.useEffect(() => {
    if (context.isUndefined(plaidAccounts)) {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'get-plaid-accounts'
      })
    }
    // eslint-disable-next-line
  }, [globalActions.api, plaidAccounts])

  const getState = (row) => {
    let content = 'Active'
    if (row.error) {
      switch (row.error.error_code) {
        case 'ITEM_LOGIN_REQUIRED':
          content = 'Login Required'
          break
        default:
          content = 'Error'
          break
      }
    }
    return (
      <strong>
        {content}
      </strong>
    )
  }

  if (context.isUndefined(plaidAccounts)) return <Skeleton variant="text" width='100%' height={150} />

  return (
    <TableContainer >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Bank</TableCell>
            <TableCell align="right">Account</TableCell>
            <TableCell align="right">Count</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Plaid State</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!context.isDefined(plaidAccounts) ? <>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" colSpan={5}>
                <Link to='/donor/rounding-account' style={{ textDecoration: 'none' }}>
                  <Button size="small">Click to add rounding account</Button>
                </Link>
              </TableCell>
            </TableRow>
          </> : <>
            {plaidAccounts.map((row) => (
              <TableRow
                key={context.genKey(row)}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.mask}</TableCell>
                <TableCell align="right">{row.item_count}</TableCell>
                <TableCell align="right">${context.formatMoney(row.amount)}</TableCell>
                <TableCell align="right">{getState(row)}</TableCell>
              </TableRow>
            ))}
          </>}
        </TableBody>
      </Table>
    </TableContainer>
  )

}

export default function OutlinedCard () {
  return (
    <Box sx={{ minWidth: 275, height: '100%' }}>
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%'
        }}>
        <CardContent>
          <Typography align="center" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Rounding Accounts
          </Typography>
          <OtherTable />
        </CardContent>
        <CardActions>
          <Link to='/donor/rounding-account' style={{ textDecoration: 'none' }}>
            <Button size="small">View All Accounts</Button>
          </Link>
          <Link to='/donor/rounding-account' style={{ textDecoration: 'none' }}>
            <Button size="small">SwipeGive Settings</Button>
          </Link>
        </CardActions>
      </Card>
    </Box >
  )
}