import * as React from 'react'
import Typography from '@mui/material/Typography'
import context from 'lib/context'
import useGlobal from 'store'

export default function PlaidLink () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  return (
    <>
      <Typography variant='h4' sx={{ m: 1 }}>
        Rounding Account
      </Typography>
      <Typography variant="body1" sx={{ m: 1 }}>
        Your active, linked rounding account(s) will be used to calculate your monthly rounding donation.
        You will be charged once a month on our billing cycle, and it will continue until you pause or cancel.
        The minimum recurring donation is ${context.formatMoney(globalState.settings.minimumCharge)}.
      </Typography>
    </>
  )
}