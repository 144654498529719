import * as React from 'react'
import Typography from '@mui/material/Typography'
import useGlobal from 'store'
import context from 'lib/context'

export default function OutlinedCard () {
  // eslint-disable-next-line no-unused-vars
  const [globalState, globalActions] = useGlobal()
  const { current_user } = globalState.repo || { undefined }
  const {
    currentMonthRounding = 0
  } = globalState.dashboard

  React.useEffect(() => {
    if (currentMonthRounding) return
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-current-month-rounding'
    })
    // eslint-disable-next-line
  }, [globalActions.api, globalActions.control, currentMonthRounding])

  return (<>
    {current_user && current_user.full_name &&
      <Typography variant="h6" component="span">
        {current_user.full_name},&nbsp;
      </Typography>
    }
    {
      currentMonthRounding ?
        <Typography component="span">
          You have rounded up ${context.formatMoney(currentMonthRounding)} this  month. Nice!
        </Typography> :
        <Typography component="span">
          You have rounded up ${context.formatMoney(currentMonthRounding)} this  month.
        </Typography>
    }
  </>
  )
}