import * as React from 'react'
import 'braintree-web'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import context from 'lib/context'
import DropIn from 'braintree-web-drop-in-react'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import useGlobal from 'store'

function Component () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()

  const { environment } = globalState.controls
  const { client_token } = globalState.braintree


  React.useEffect(() => {
    if (!client_token) {
      globalActions.api.performApi({
        apiName: 'Public',
        apiPath: '/public',
        apiAction: 'get-braintree-token'
      })
    }
    // eslint-disable-next-line
  }, [globalActions.api, client_token])

  if (!client_token) return null

  const validate = () => {
    alert('validated')
  }

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <DropIn
        options={{
          authorization: client_token,
          card: {},
          paypal: { flow: 'vault' },
          dataCollector: {
            kount: { environment: environment === 'production' ? 'production' : 'sandbox' }
          }
        }}
        //onInstance={(instance) => { setInstance(instance) }}
        onChange={() => { }}
      />
      <Button onClick={validate} >Validate Payment Method </Button>
    </Box>
  )
}

export default function SignIn () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  return (
    <Container component='main' maxWidth='md'>
      <Box
        sx={{
          backgroundColor: 'white',
          mt: 8,
          p: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='h3'>
          Funding Card Setup
        </Typography>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography variant='body1'>
          Your funding credit card will be used for this monthly donation.
          You will be charged once a month on our billing cycle, and it
          will continue until you turn your recurring donation off on this page.
          The minimum recurring donation is ${context.formatMoney(globalState.settings.minimumCharge)}.
        </Typography>
        <Component />
      </Box>
    </Container >
  )
}