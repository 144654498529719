import * as React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Instructions from './Instructions'
import ShowTable from './ShowTable'
import AddNew from './AddNew'

import Header from 'components/Header'

export default function PlaidLink () {
  return (
    <>
      <Header label="Rounding Account" />
      <Container
        sx={{
          backgroundColor: 'palette.background.dark',
          paddingBottom: 3,
          paddingTop: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Instructions />
          <ShowTable />
          <AddNew />
        </Box>
      </Container>
    </>
  )
}