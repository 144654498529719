import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TermsOfService from './TermsOfService'
import useGlobal from 'store'

export default function ScrollDialog ({ label }) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { termsOfService } = globalState.notifications

  const [scroll, setScroll] = React.useState('paper')

  const handleClickOpen = (scrollType) => () => {
    globalActions.control.mergeState({
      notifications: {
        termsOfService: true
      }
    })
    setScroll(scrollType)
  }

  const handleClose = () => {
    globalActions.control.mergeState({
      notifications: {
        termsOfService: false
      }
    })
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (termsOfService) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [termsOfService])

  return (
    <>
      <Box
        onClick={handleClickOpen('paper')}
        variant="link"
        style={{ cursor: 'pointer' }}
        sx={label}
      >
        Terms Of Service
      </Box>
      <Dialog
        open={termsOfService}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Terms of Service</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box sx={{
              fontSize: '80%'
            }}>
              <TermsOfService />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}