import useGlobal from 'store'
import useEventListener from '@use-it/event-listener'

const Component = () => {
  const [globalState, globalActions] = useGlobal()

  function handler (event) {
    if (!globalState.forms) return
    const { current } = globalState.forms
    if (event.ctrlKey && event.code === 'KeyS') {
      event.stopPropagation()
      event.preventDefault()
      if (current.fields.sys_id.widget.value) {
        globalActions.control.publish('setForm', { action: { formName: 'current', type: 'button', name: '@save-keep' } })
      }
    }
    if (event.code === 'Escape') {
      if (current.fields.sys_id.widget.value) {
        event.stopPropagation()
        event.preventDefault()
        globalActions.control.publish('setForm', { action: { formName: 'current', type: 'button', name: '@clear' } })
      }
    }
    if (event.ctrlKey && event.code === 'KeyR') {
      // If form loaded,refresh the data in the form from the database. Otherwise, pass through to browser.

      if (current.fields.sys_id.widget.value) {
        event.stopPropagation()
        event.preventDefault()
        globalActions.control.publish('setForm', { action: { formName: 'current', type: 'button', name: '@load' } })
      }
    }
    if (event.ctrlKey && (event.code === 'KeyN' || event.code === 'KeyI')) {
      // In Chrome, you are not allowed to override Cntrl-N under any circumstances
      // Ctrl-I is a duplicate of this feature, and can be remembered as "[I]nsert new record"
      event.stopPropagation()
      event.preventDefault()
      globalActions.control.publish('setForm', { action: { formName: 'current', type: 'button', name: '@new' } })
    }
    if (event.ctrlKey && event.code === 'KeyD') {
      // Delete the current object from the database.
      if (current.fields.sys_id.widget.value) {
        event.stopPropagation()
        event.preventDefault()
        globalActions.control.publish('setForm', { action: { formName: 'current', type: 'button', name: '@delete' } })
      }
    }
    return false
  }

  useEventListener('keydown', handler)
  return null
}
export default Component
