import * as React from 'react'
import useGlobal from 'store'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import SelectOrganization from './SelectOrganization'
import CreateAccount from './CreateAccount'
import context from 'lib/context'

function Component () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { supported } = globalState.form

  const icon = (
    <CreateAccount />
  )

  return (
    <>
      <SelectOrganization />
      <Box sx={{ display: 'flex' }}>
        <Fade in={!context.isEmpty(supported)}>{icon}</Fade>
      </Box>
    </>
  )
}

export default Component
