import * as React from 'react'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import Header from 'components/Header'
import useGlobal from 'store'

function Component () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { spotDonation } = globalState
  const { current_user } = globalState.repo || { undefined }

  if (!(!!current_user && !!spotDonation)) return null

  return (
    <>
      <Header label="Donation" />
      <Container
        sx={{
          backgroundColor: 'palette.background.dark',
          paddingBottom: 3,
          paddingTop: 3
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
            <Typography
              variant="h5"
              align="center"
              sx={{ mb: 3 }}
            >
              Thank you for your Donation!
            </Typography>
            <Typography
              sx={{ mb: 3 }}
            >
              Thank you for your ${spotDonation.value} contribution to support {spotDonation.supported_organization || current_user.default_supported_customer}.
            </Typography>
          </Paper>
        </Box>
      </Container>
    </>)
}

export default Component