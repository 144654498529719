import * as React from 'react'
import { Link, Button, Paper, Typography } from '@mui/material'
import ParallaxScroll from 'components/Parallax/Scroll'
import { PublicTopBar, PublicFooter } from 'components'
import useGlobal from 'store'
import context from 'lib/context'


const Component = () => {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  globalActions.control.beforeLayout()

  return (
    <>
      <PublicTopBar />
      <ParallaxScroll
        backgroundImage={context.imageUrl('/cc/CCWebImage1-Main.jpg')}
        minHeight='100vh'
      >
        <Paper sx={{
          height: '100%',
          p: 3,
          backgroundColor: 'primary.dark',
          width: '80%',
          ml: 'auto',
          mr: 'auto',
          mt: '20vh',
          textAlign: 'center',
          maxWidth: '750px'
        }}>
          <Typography sx={{
            p: 2,
            m: 2,
            color: 'primary.light',
            opacity: 1,
            fontWeight: 700,
            fontSize: '32px'
          }}>
            Development <span sx={{
              fontWeight: 500
            }}>Through</span> Intelligence
          </Typography>
          <Typography sx={{
            m: 2,
            color: 'primary.contrastText',
            opacity: 1,
            fontWeight: 500,
            fontSize: '23px'
          }}>
            CaringCent is your expert college athletics development partner.
          </Typography>
          <Typography sx={{
            p: 2,
            m: 2,
            color: 'primary.contrastText',
            opacity: 1,
            fontWeight: 500,
            fontSize: '18px'
          }}>
            We ADD to your development strategy and efforts by combining intelligent technologies with customized service to help you activate the massive, UNTAPPED donation revenue with no additional effort from your staff or new expenses.
          </Typography>
          <Link
            sx={{ color: 'white', textDecoration: 'none' }}
            href='https://www.caringcent.com/how-we-partner/'
          >
            <Button
              variant='contained'
              color='secondary'
            >
              Learn How We Partner
            </Button>
          </Link>

        </Paper>
      </ParallaxScroll>
      <ParallaxScroll
        backgroundImage={context.imageUrl('/cc/txstbobcatbasketball.jpg')}
        minHeight='100vh'
      >
        <Paper sx={{
          height: '100%',
          p: 3,
          backgroundColor: 'primary.dark',
          width: '80%',
          ml: 'auto',
          mr: 'auto',
          mt: '20vh',
          textAlign: 'center',
          maxWidth: '750px'
        }}>
          <Typography sx={{
            p: 2,
            m: 2,
            color: 'primary.light',
            opacity: 1,
            fontWeight: 700,
            fontSize: '32px'
          }}>
            Development <span sx={{
              fontWeight: 500
            }}>Through</span> Intelligence
          </Typography>
          <Typography sx={{
            m: 2,
            color: 'primary.contrastText',
            opacity: 1,
            fontWeight: 500,
            fontSize: '23px'
          }}>
            CaringCent is your expert college athletics development partner.
          </Typography>
          <Typography sx={{
            p: 2,
            m: 2,
            color: 'primary.contrastText',
            opacity: 1,
            fontWeight: 500,
            fontSize: '18px'
          }}>
            We ADD to your development strategy and efforts by combining intelligent technologies with customized service to help you activate the massive, UNTAPPED donation revenue with no additional effort from your staff or new expenses.
          </Typography>
          <Link
            sx={{ color: 'white', textDecoration: 'none' }}
            href='https://www.caringcent.com/how-we-partner/'
          >
            <Button
              variant='contained'
              color='secondary'
            >
              Learn How We Partner
            </Button>
          </Link>
        </Paper>
      </ParallaxScroll>
      <PublicFooter
        style={{ background: context.gradient }}
        sx={{
          pt: 3,
          pb: 3,
        }}
      />
    </>
  )
}

export default Component
