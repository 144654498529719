import * as React from 'react'
import useGlobal from 'store'
import Header from 'components/Header'
import context from 'lib/context'
import RallySettingsCard from './RallySettingsCard'

import {
  Box,
  Container,
  Paper,
  Typography,
} from '@mui/material'

function Component () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { rows: rallygive_settings } = globalState.repo.rallygive_settings || { undefined }
  const { default_supported_customer } = globalState.repo.current_user || { undefined }

  React.useEffect(() => {
    if (context.isDefined(rallygive_settings)) return
    if (!context.isDefined(default_supported_customer)) return
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-rally-settings',
      apiPayload: {
        'client': default_supported_customer
      }
    })
  }, [
    default_supported_customer,
    rallygive_settings,
    globalActions.api
  ])
  if (context.isUndefined(rallygive_settings)) return
  return <>
    <Header label='Pledged Donations' />
    <Container
      sx={{
        paddingBottom: 3,
        paddingTop: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='h4' sx={{ m: 1 }}>
          Pledged Donations
        </Typography>
        <Typography variant="body1" sx={{ m: 1 }}>
          You will be charged once-per-month to your funding card, and the minimum donation is ${context.formatMoney(globalState.settings.minimumCharge)}.
        </Typography>
        <Typography variant="caption" sx={{ m: 1 }}>
          If you pledged multiple campaigns for the same organization, please note you may be charged multiple times in a single month. This only applies
          to multiple campaigns, and not multiple campaign metrics within a single campaign.
        </Typography>
      </Box>
      <Box sx={{ position: 'relative', p: 3 }}>
        {context.isDefined(rallygive_settings) && context.isNotEmpty(rallygive_settings) ?
          rallygive_settings.map((row, idx) => {
            return <RallySettingsCard
              key={context.genKey(row)}
              row={row}
              idx={idx}
              sx={{ m: 1 }}
            />
          }) : <Paper>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 5
              }}
            >
              You do not have any Rally donations set up.
            </Box>
          </Paper>}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

      </Box>
    </Container>
  </>
}

export default Component