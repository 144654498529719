import * as React from 'react'
import 'braintree-web'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import DropIn from 'braintree-web-drop-in-react'
import useGlobal from 'store'

export default function FormDialog () {
  const [open, setOpen] = React.useState(false)
  const [instance, setInstance] = React.useState()
  const [errors, setErrors] = React.useState({})
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()

  const { environment } = globalState.controls
  const { client_token } = globalState.braintree

  React.useEffect(() => {
    if (!client_token) {
      globalActions.api.performApi({
        apiName: 'Public',
        apiPath: '/public',
        apiAction: 'get-braintree-token'
      })
    }
    // eslint-disable-next-line
  }, [globalActions.api, client_token])

  if (!client_token) return null

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAdd = async () => {
    const nonce = await instance.requestPaymentMethod()
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'add-braintree-payment-method',
      apiPayload: {
        nonce
      },
      stateReducer: (store, response) => {
        if (response.errors) {
          setErrors({ ...errors, ...response.errors })
        } else {
          setOpen(false)
          globalActions.api.performApi({
            apiName: 'Events',
            apiPath: '/event',
            apiAction: 'get-payment-profiles'
          })
        }
      }
    })
  }

  return (
    <div>
      <style>
        {`
         [data-braintree-id='discover-card-icon']  {
            display: inline-block!important;
         }
        [data-braintree-id='jcb-card-icon'],
        [data-braintree-id='unionpay-card-icon'],
        [data-braintree-id='hiper-card-icon'] {
          display: none!important;
          }
        `}
      </style>
      <Button variant='outlined' onClick={handleClickOpen}>
        Add New Funding Card
      </Button>
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>Add Funding Card/Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select how you want to fund your donations. When you are done entering in your information, click 'Add' down below to complete the process.
            If you no longer wish to add a new funding account, click 'Cancel' below.
          </DialogContentText>
          <Container sx={{ mt: 5 }}>
            {errors.payment_error &&
              <Alert severity='error'>
                {errors.payment_error}
              </Alert>}
            <DropIn
              options={{
                authorization: client_token,
                translations: {
                  payingWith: 'You are paying with {{paymentSource}}',
                  chooseAnotherWayToPay: 'Don\'t want to use this funding source? Click here to choose a different way to fund your donations.',
                  chooseAWayToPay: 'Please select from the options below:',
                  payWithCard: 'Credit Card',
                },
                vaultManager: true,
                card: {
                  cardholderName: true,
                },
                paypal: { flow: 'vault' },
                dataCollector: {
                  kount: { environment: environment === 'production' ? 'production' : 'sandbox' }
                }
              }}
              onInstance={setInstance}
              onChange={() => { setErrors({ ...errors, ...{ payment_error: null } }) }}
            />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant='contained' onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}