import React, { useRef } from 'react'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import Introduction from './Introduction'
import FundingCardSetup from './FundingCardSetup'
import RoundingSetup from './RoundingSetup'
import MonthlyRecurringSetup from './MonthlyRecurringSetup'

// Little helpers ...
const url = (name, wrap = false) =>
  `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`

export default function App () {
  const parallax = useRef()
  return (
    <div
      name="setup-wizard-container"
      style={{ width: '100%', height: '100%', background: '#253237' }}
    >
      <Parallax ref={parallax} pages={4} style={{ position: 'inherit' }}>
        <ParallaxLayer offset={1} speed={1} style={{ backgroundColor: '#805E73' }} />
        <ParallaxLayer offset={2} speed={1} style={{ backgroundColor: '#87BCDE' }} />

        <ParallaxLayer
          offset={0}
          speed={0}
          factor={3}
          style={{
            backgroundImage: url('stars', true),
            backgroundSize: 'cover',
          }}
        />

        <ParallaxLayer offset={1.3} speed={-0.3} style={{ pointerEvents: 'none' }}>
          <img alt='a' src={url('satellite4')} style={{ width: '15%', marginLeft: '70%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0.8} style={{ opacity: 0.1 }}>
          <img alt='a' src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '55%' }} />
          <img alt='a' src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '15%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={1.75} speed={0.5} style={{ opacity: 0.1 }}>
          <img alt='a' src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '70%' }} />
          <img alt='a' src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '40%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0.2} style={{ opacity: 0.2 }}>
          <img alt='a' src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '10%' }} />
          <img alt='a' src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '75%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={1.6} speed={-0.1} style={{ opacity: 0.4 }}>
          <img alt='a' src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '60%' }} />
          <img alt='a' src={url('cloud')} style={{ display: 'block', width: '25%', marginLeft: '30%' }} />
          <img alt='a' src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '80%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={2.6} speed={0.4} style={{ opacity: 0.6 }}>
          <img alt='a' src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '5%' }} />
          <img alt='a' src={url('cloud')} style={{ display: 'block', width: '15%', marginLeft: '75%' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={2.5}
          speed={-0.4}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
          }}>
          <img alt='a' src={url('earth')} style={{ width: '60%' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={-0.3}
          style={{
            backgroundSize: '80%',
            backgroundPosition: 'center',
            backgroundImage: url('clients', true),
          }}
        />

        <ParallaxLayer
          offset={0}
          speed={0.1}
          onClick={() => parallax.current.scrollTo(1)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Introduction />
        </ParallaxLayer>

        <ParallaxLayer
          offset={1}
          speed={0.1}
          onXClick={() => parallax.current.scrollTo(2)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <FundingCardSetup />
        </ParallaxLayer>

        <ParallaxLayer
          offset={2}
          speed={.1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => parallax.current.scrollTo(3)}>
          <RoundingSetup />
        </ParallaxLayer>

        <ParallaxLayer
          offset={3}
          speed={-0}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => parallax.current.scrollTo(0)}>
          <MonthlyRecurringSetup />
        </ParallaxLayer>
      </Parallax>
    </div>
  )
}
