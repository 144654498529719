import * as React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import routes from 'store/routes'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import theme from 'theme'

const Routes = () => {
  const routing = useRoutes(routes)
  return (
    <>
      {routing}
    </>
  )
}

const App = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <HelmetProvider>
            <CssBaseline />
            <Routes />
          </HelmetProvider>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  )
}

export default App
