import * as React from 'react'

import {
  Box,
  Grid,
  Typography,
  Link,
  Container
} from '@mui/material'

import {
  //ServiceFeesPopup,
  PrivacyPolicyPopup,
  TermsOfServicePopup
} from 'views'


function Copyright (props) {
  return (
    <Typography variant='body2' color='text.secondary' align='center' {...props}>
      {'Copyright © '}
      <Link color='inherit' href='https://www.caringcent.com/'>
        CaringCent, LLC
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}


const footers = [
  {
    title: 'Services',
    description: [
      { label: 'Methodology', link: 'https://www.caringcent.com/methodology/' },
      { label: 'How We Partner', link: 'https://www.caringcent.com/how-we-partner/' },
      { label: 'Partnership Case Studies', link: 'https://www.caringcent.com/examples/' },
    ],
  },
  {
    title: 'Company',
    description: [
      { label: 'Our Story', link: 'https://www.caringcent.com/our-story/' },
      { label: 'News and Media', link: 'https://www.caringcent.com/media-coverage/' },
    ],
  },
  // {
  //   title: 'Resources',
  //   description: [
  //     { label: 'Resource', link: '#' },
  //     { label: 'Resource name', link: '#' },
  //     { label: 'Another resource', link: '#' },
  //     { label: 'Final resource', link: '#' }
  //   ],
  // },
  {
    title: 'Legal',
    description: [
      { label: <TermsOfServicePopup />, link: '#', key: 'termsofservice' },
      { label: <PrivacyPolicyPopup />, link: '#', key: 'privacypolicy' },
      //{ label: <ServiceFeesPopup />, link: '#' }
    ],
  },
]

export default function Component (props) {
  return (
    <React.Fragment>
      <Container
        maxWidth={false}
        component='footer'
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        {...props}
      >
        <Box sx={{
          display: { xs: 'none', sm: 'block' }
        }}>
          <Grid container spacing={4} justifyContent='space-evenly'>
            {footers.map((footer) => (
              <Grid item xs={6} sm={3} key={footer.title}>
                <Typography variant='h6' color='text.primary' gutterBottom>
                  {footer.title}
                </Typography>
                <ul>
                  {footer.description.map((item) => (
                    <li key={item.key || item.label}>
                      {item.link ? <>
                        <Link href={item.link} variant='subtitle1' color='text.secondary'>
                          {item.label}
                        </Link></> : <>
                        {item.label}
                      </>}
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </React.Fragment >
  )
}