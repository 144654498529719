import * as React from 'react'
import Button from '@mui/material/Button'
import { usePlaidLink } from 'react-plaid-link'
import useGlobal from 'store'

export default function PlaidLink ({ token, children }) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const [linkToken, setLinkToken] = React.useState()

  // eslint-disable-next-line
  const { open, ready, error, exit } = usePlaidLink({
    token: linkToken,
    onEvent: (eventName, metadata) => {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'track-plaid-event',
        apiPayload: {
          eventName,
          metadata
        }
      })
    },
    onExit: (error, metadata) => { },
    onLoad: () => { },
    onSuccess: (public_token, metadata) => {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'get-plaid-accounts'
      })
    },
  })

  React.useEffect(() => {
    if (!linkToken) {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'get-plaid-update-token',
        apiPayload: {
          access_token: token
        },
        stateReducer: (store, response) => {
          setLinkToken(response.link_token)
        },
      })
    }
    // eslint-disable-next-line
  }, [globalActions.api, linkToken])

  return (
    <>
      {error && <div>{error}</div>}
      <Button onClick={() => open()} disabled={!ready} variant='text'>
        {children}
      </Button>
    </>
  )
}