import WyomingTTF from './Wyoming.ttf'
import WackerDisplayTTF from './WackerDisplay.ttf'
import WackerOutlineTTF from './WackerOutline.ttf'
import LamarBoldTTF from './LamarBold.ttf'
import LobosFontTTF from './LobosFont.ttf'
import GraduateTTF from './Graduate.ttf'
import ApexBoldOTF from './ApexMk2-BoldExtended.otf'
import ApexLightOTF from './ApexMk2-LightCondensed.otf'
import ApexRegOTF from './ApexMk2-Regular.otf'
import UniversLightOTF from './UniversLight.otf'
import AirCorpsOTF from './AirCorpsJNL.otf'
import CeraRegularOTF from './Cera-Regular.otf'
import BeaverBoldOTF from './BeaverBold.otf'

const Wyoming = {
  fontFamily: 'Wyoming',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Wyoming'),
    url(${WyomingTTF})
    format('truetype')
  `
}

const LamarBold = {
  fontFamily: 'LamarBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('LamarBold'),
    url(${LamarBoldTTF})
    format('truetype')
  `
}

const LobosFont = {
  fontFamily: 'LobosFont',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('LobosFont'),
    url(${LobosFontTTF})
    format('truetype')
  `
}

const WackerDisplay = {
  fontFamily: 'WackerDisplay',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('WackerDisplay'),
    url(${WackerDisplayTTF})
    format('truetype')
  `
}

const WackerOutline = {
  fontFamily: 'WackerOutline',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('WackerOutline'),
    url(${WackerOutlineTTF})
    format('truetype')
  `
}

const ApexBold = {
  fontFamily: 'ApexBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('ApexBold'),
    url(${ApexBoldOTF}) 
    format('otf')
  `
}
const ApexReg = {
  fontFamily: 'ApexReg',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('ApexReg'),
    url(${ApexRegOTF}) 
    format('otf')
  `
}
const Graduate = {
  fontFamily: 'Graduate',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Graduate'),
    url(${GraduateTTF}) 
    format('truetype')
  `
}
const ApexLight = {
  fontFamily: 'ApexLight',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('ApexLight'),
    url(${ApexLightOTF}) 
    format('otf')
  `
}

const UniversLight = {
  fontFamily: 'UniversLight',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('UniversLight'),
    url(${UniversLightOTF})
    format('truetype')
  `
}

const AirCorps = {
  fontFamily: 'AirCorps',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('AirCorps'),
    url(${AirCorpsOTF})
    format('truetype')
  `
}

const CeraRegular = {
  fontFamily: 'CeraReg',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('CeraReg'),
    url(${CeraRegularOTF})
    format('truetype')
  `
}

const BeaverBold = {
  fontFamily: 'BeaverBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    local('BeaverBold'),
    url(${BeaverBoldOTF}) 
    format('truetype')
  `
}

export const CustomFontDefs = [
  AirCorps,
  ApexBold,
  ApexLight,
  ApexReg,
  BeaverBold,
  CeraRegular,
  Graduate,
  LamarBold,
  LobosFont,
  UniversLight,
  WackerDisplay,
  WackerOutline,
  Wyoming
]

export const AvailableFonts = [
  'Open Sans',
  'sans-serif',
  ...CustomFontDefs.map((item) => { return item.fontFamily }),
  'Arial',
  'Arial Black',
  'Verdana',
  'Tahoma',
  'Trebuchet MS',
  'Impact',
  'Times New Roman',
  'Didot',
  'Georgia',
  'American Typewriter',
  'Andalé Mono',
  'Courier',
  'Lucida Console',
  'Monaco',
  'Bradley Hand',
  'Brush Script MT',
  'Luminari',
  'Comic Sans MS'
]
