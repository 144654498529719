import * as React from 'react'
import useGlobal from 'store'

function Component () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()

  const open = (item) => () => {
    globalActions.control.mergeState({
      notifications: {
        termsOfService: false,
        serviceFees: false,
        privacyPolicy: false,
        [item]: true
      }
    })
  }
  return (
    <>
      <p>CaringCent (&ldquo;<strong>CaringCent</strong>,&rdquo; &ldquo;<strong>us</strong>,&rdquo; &ldquo;<strong>we</strong>,&rdquo; or &ldquo;<strong>our</strong>&rdquo;) is committed to protecting your privacy. CaringCent has prepared this Privacy Policy (this &ldquo;<strong>Privacy Policy</strong>&rdquo;) to describe to you, or if you represent an entity or other organization, that entity or organization, (in either case, &ldquo;<strong>You</strong>&rdquo;) our practices regarding the personal information that CaringCent collects, uses, and shares on behalf of end users (&ldquo;<strong>Users</strong>&rdquo;) of our online platform for making and receiving donations (&ldquo;<strong>Platform</strong>&rdquo;). Capitalized terms not defined in this Privacy Policy, shall have the meetings as defined in the CaringCent <u>
        <button
          type="button"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            textDecoration: 'underline',
            display: 'inline',
            m: 0,
            p: 0,
          }}
          onClick={open('termsOfService')}
        >
          Terms Of Service
        </button>
      </u>.</p>
      <p>CaringCent reserves the right, at any time, to modify this Privacy Policy. If we make revisions that change the way we collect, use, or share personal information, we will post those changes in this Privacy Policy. You should review this Privacy Policy periodically so that You keep up to date on our most current policies and practices. We will note the effective date of the latest version of our Privacy Policy at the end of this Privacy Policy. </p>
      <ol>
        <li>
          <p><strong>Collection of Personal Information</strong>. Personal information refers to information that is connected to Your name and may include things such as Your email address, mailing address, and phone number if provided. </p>
        </li>
      </ol>
      <p>CaringCent collects personal information when Users voluntarily submit it to us. For example, we may collect or receive information from You when You create a User Account, edit Your Registration Information, make a donation through the Platform, or use or access a Service or Content available through the Platform. In addition to personal information that we may receive from You, CaringCent may collect other information from Users using the following technologies: </p>
      <ul>
        <li>
          <p><u>Cookies</u>. "Cookies" are small bits of information that the Platform places on the hard drive of Your computer. Cookies remember information about Your activities on the Platform to make Your visits to the Platform more enjoyable and valuable to You by providing a customized experience and recognizing Your preferences when You visit the Platform. If You've chosen to disable cookies on Your browser, some of the functionality of the Platform may be lost. Certain Services provided by CaringCent, require that cookies be enabled in Your browser and cannot be used when You have disabled cookies in Your browser.</p>
        </li>
        <li>
          <p><u>Flash Cookies</u>. (also called Local Shared Objects or &ldquo;LSOs&rdquo;). Flash cookies are data files similar to cookies, except that they can store more complex data. Flash cookies are used to remember settings, preferences, and usage, particularly for video, interactive gaming, and other similar services. </p>
        </li>
        <li>
          <p><u>Web beacons or clear pixels</u>. Web beacons are small graphic images on a web page or in an email that can be used for such things as recording the pages and advertisements that You select, or tracking the performance of email marketing campaigns. </p>
        </li>
        <li>
          <p><u>Web server logs</u>. Web server logs are records of activity created by the computer that delivers the webpages You request to Your browser. For example, a web server log may record the search term You entered or the link You clicked to bring You to the webpage. The web server log also may record information about Your browser, such as Your IP address and the cookies set on Your browser by the server. </p>
        </li>
      </ul>
      <ol start="2">
        <li>
          <p><strong>USE OF PERSONAL INFORMATION</strong>. CaringCent&rsquo;s primary purpose in collecting personal information is to facilitate the Services that you select on the Platform. CaringCent may also use personal information for various purposes, including without limitation to:</p>
        </li>
      </ol>
      <ul>
        <li>
          <p>To help diagnose problems with our Platform, to administer our Platform, and to enhance the Platform for optimal User experience. </p>
        </li>
        <li>
          <p>To monitor the usage and performance of our Platform, and to determine aggregate information about our Users.</p>
        </li>
        <li>
          <p>To facilitate the processing of transactions between Users and to send receipts. </p>
        </li>
        <li>
          <p>To provide maintenance, support, and customer service for the Platform. </p>
        </li>
        <li>
          <p>To contact Users for information verification purposes and to administer User Accounts.</p>
        </li>
      </ul>
      <ol start="3">
        <li>
          <p><strong>Sharing of Personal Information</strong>. </p>
        </li>
      </ol>
      <p>Any Organization you make a contribution to through the Platform will have access to Your personal information. Organizations will not have any access to payment card information, rounding account personal information, passwords, and credit card purchase data. You consent to the foregoing and agree that we are not responsible for how these Organizations handle Your personal information. You should contact them directly for their terms and privacy policies. </p>
      <p>CaringCent may share Your personal information with service providers that perform services for CaringCent that are necessary for the orderly operation of the Platform, such as providing marketing assistance, analyzing User Content, and processing payment card information. CaringCent contracts with service providers, such as payment processing providers and rounding solution providers, to process all donations that Users make through the Platform. Personal information handled by these service providers is subject to their terms and privacy policies. Please review the service provider&rsquo;s terms and privacy policies to better understand how they manage your data. Among the various service providers that CaringCent utilizes, CaringCent works with Plaid to provide various Services to You. By accessing or using the Platform, Content, or Services, You expressly agree to and grant Plaid the right to use Your personal information in accordance with the <u>
        <a href="https://www.plaid.com/legal/#Privacy-Policy">Plaid Privacy Policy</a></u>.</p>
      <p>There may also be instances when CaringCent may disclose Your personal information without providing You with a choice in order to protect the legal rights of CaringCent, other affiliated companies or their employees, agents, and contractors; to honor a request that You have made through the Platform; to protect the safety and security of other Users; to protect against fraud or for risk management purposes; or to comply with the Law or legal process (e.g., to protect You and other Users against identity theft). In addition, if CaringCent sells all or part of its business or makes a sale or transfer of assets or is otherwise involved in a merger or business transfer, CaringCent may transfer Your Personal Information to a third party as part of that transaction.</p>
      <p>The Platform may offer You the ability to share Your personal information through a social networking site (e.g., Facebook, Twitter), using such site&rsquo;s integrated tools (e.g., Facebook &ldquo;Like&rdquo; button, or Twitter &ldquo;Tweet&rdquo; button). The use of such integrated tools enables You to share personal information about Yourself with other individuals or the public, depending on the settings that You have established with such social networking site. For more information about the purpose and scope of data collection and use in connection with such social networking site or a site&rsquo;s integrated tools, please visit the privacy policies of the entities that provide these social networking sites. </p>
      <ol start="4">
        <li>
          <p><strong>Security</strong>. CaringCent employs reasonable security measures to protect personal information from becoming disclosed to individuals who are not described in this Privacy Policy. While there is no such thing as &ldquo;perfect security&rdquo; on the Internet, we will take reasonable steps to ensure the safety of Your personal information. </p>
        </li>
        <li>
          <p><strong>NOTICE TO CALIFORNIA RESIDENTS</strong>. Under California Civil Code sections 1798.83-1798.84, California residents who have an established business relationship with CaringCent are entitled to ask us for a notice describing what categories of personal customer information we share with third parties for their direct marketing purposes. This notice will identify the categories of information shared with and will include a list of the third parties with which it is shared, along with their names and addresses. If You are a California resident and would like a copy of this notice, please submit a written request to us at the addresses listed below.</p>
        </li>
        <li>
          <p><strong>Communications</strong>. If You have any additional questions or concerns about our Privacy Policy or any other privacy or security issue, please contact us at:</p>
        </li>
      </ol>
      <p>CaringCent</p>
      <p>Attn: CaringCent Privacy Officer</p>
      <p>317 Commercial Street NE</p>
      <p>Albuquerque, Nm: 87102</p>
      <p>info@caringcent.com</p>
      <p>844-276-8634</p>
      <p><br /> </p>
      <p>If CaringCent needs, or is required, to contact You concerning any event that involves information about You, we may do so by email, telephone, or mail. </p>
      <p><br /> </p>
      <p>April 2022</p>
    </>
  )
}

export default Component
