import * as React from 'react'

import * as Layouts from 'layouts'
import * as Views from 'views'

const routes = [
  {
    path: 'donor',
    element: <Layouts.ProtectedLayout />,
    children: [
      { path: 'dashboard', element: <Views.MyDashboard /> },
      { path: 'datastore', element: <Views.DataStoreView /> },
      { path: 'funding-account', element: <Views.MyFundingAccount /> },
      { path: 'rounding-account', element: <Views.MyRoundingAccount /> },
      { path: 'my-profile', element: <Views.MyProfile /> },
      { path: 'my-reports', element: <Views.MyReports /> },

      { path: 'recurring-settings', element: <Views.MyRecurringSettings /> },
      { path: 'rounding-settings', element: <Views.MyRoundingSettings /> },
      { path: 'rally-settings', element: <Views.MyRallySettings /> },
      { path: 'make-donation', element: <Views.MakeDonation /> },
      { path: 'thankyou', element: <Views.ThankYou /> },

      { path: 'reports', element: <Views.DataStoreView /> },
      { path: 'donation-history', element: <Views.DataStoreView /> },
      { path: 'current-month-rounding', element: <Views.DataStoreView /> },
      { path: 'previous-month-rounding', element: <Views.DataStoreView /> },
      { path: 'year-end-tax-reports', element: <Views.DataStoreView /> },

      { path: '*', element: <Views.NotFoundView /> },
      {
        path: 'oauth',
        children: [
          { path: 'plaid', element: <Views.NotFoundView /> }, //TBD: Create return page
          { path: '*', element: <Views.NotFoundView /> }
        ]
      },
    ]
  },
  {
    path: 'account',
    element: <Layouts.ProtectedLayout />,
    children: [
      { path: '*', element: <Views.MyDashboard /> },
    ]
  },
  {
    path: 'register',
    element: <Layouts.PublicLayout />,
    children: [
      { path: 'new', element: <Views.NewRegistration /> },
      { path: 'swipegive', element: <Views.SwipeGiveRegistration /> },
      { path: 'account', element: <Views.AccountRegistration /> },
      { path: 'account2', element: <Views.AccountRegistration2 /> },
    ]
  },
  {
    path: 'go',
    element: <Layouts.PublicLayout />,
    children: [
      { path: ':vanityurl', element: <Views.VanityRegistration /> },
      { path: 'success', element: <Views.SwipeGiveSuccess /> },
    ]
  },
  {
    path: 'nonprofits',
    element: <Layouts.PublicLayout />,
    children: [
      { path: 'info', element: <Views.SwipeGiveRegistration /> },
    ]
  },
  {
    path: 'notifications',
    element: <Layouts.PublicLayout />,
    children: [
      { path: 'maintenance', element: <Views.SiteMaintenanceInProgress /> },
      { path: '*', element: <Views.SiteMaintenanceInProgress /> },
    ]
  },
  {
    path: 'legal',
    element: <Layouts.PublicLayout />,
    children: [
      { path: 'TermsOfService', element: <Views.TermsOfService /> },
      { path: 'PrivacyPolicy', element: <Views.PrivacyPolicy /> },
    ]
  },
  {
    path: '/',
    element: <Layouts.PublicLayout />,
    children: [
      { path: 'wizard', element: <Views.SetupWizard /> },
      { path: 'unsubscribe', element: <Views.Unsubscribe /> },
      { path: '404', element: <Views.NotFoundView /> },
      { path: '/', element: <Views.HomePageView /> },
      { path: '*', element: <Views.NotFoundView /> },
    ]
  },
]

export default routes
