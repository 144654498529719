import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import useGlobal from 'store'

export default function OutlinedCard () {
  // eslint-disable-next-line no-unused-vars
  const [globalState, globalActions] = useGlobal()

  const { current_user, current_client } = globalState.repo || { undefined }

  if (!current_user.default_supported_customer) return null
  return (
    <Box sx={{
      minWidth: 275,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Grid container>
        <Grid item>
          <Typography
            color="text.secondary"
            gutterBottom
            variant="h6">
            You are supporting&nbsp;
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            color="text.secondary"
            gutterBottom
            variant="h6">
            <Box sx={{
              textTransform: 'capitalize'
            }}>
              {current_client.client_name}
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Box >
  )
}