import * as React from 'react'
import Button from '@mui/material/Button'
import { usePlaidLink } from 'react-plaid-link'
import useGlobal from 'store'

export default function PlaidLink () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { link_token } = globalState.plaid && globalState.plaid.token

  // eslint-disable-next-line
  const { open, ready, error, exit } = usePlaidLink({
    token: link_token,
    onEvent: (eventName, metadata) => {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'track-plaid-event',
        apiPayload: {
          eventName,
          metadata
        }
      })
    },
    onExit: (error, metadata) => { },
    onLoad: () => { },
    onSuccess: (public_token, metadata) => {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'plaid-item-success',
        apiPayload: {
          public_token,
          metadata
        }
      })
    },
  })

  React.useEffect(() => {
    if (!link_token) {
      globalActions.api.performApi({
        apiName: 'Events',
        apiPath: '/event',
        apiAction: 'get-plaid-token'
      })
    }
    // eslint-disable-next-line
  }, [globalActions.api, link_token])

  return (
    <>
      {error && <div>{error}</div>}
      <Button onClick={() => open()} disabled={!ready} variant='outlined'>
        Connect a credit card account
      </Button>
    </>
  )
}