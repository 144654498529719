import { Auth, Hub } from 'aws-amplify'
import deepmerge from 'deepmerge'
import FileDownload from 'js-file-download'

import context from 'lib/context'
import * as meta from 'store/meta-data'
import * as validate from 'store/validation'
import PubSub from 'pubsub-js'

const subscriptions = []

/**
 *
 * @param {*} store
 */
export const subscribe = (store) => {
  subscriptions.push(PubSub.subscribe('formEvent', function (topic, message) {
    const { formName, event } = message
    onFormEvent(store, formName, event)
  }))
  subscriptions.push(PubSub.subscribe('setForm', function (topic, message) {
    const { action } = message
    setForm(store, action)
  }))
  subscriptions.push(PubSub.subscribe('callAfter', function (topic, message) {
    const { func, params } = message
    func(...params)
  }))
  subscriptions.push(PubSub.subscribe('dispatch', function (topic, message) {
    actionHandler(store, message)
  }))
  subscriptions.push(PubSub.subscribe('read', function (topic, message) {
    const { apiPayload, callback } = message
    store.actions.api.performApi({
      apiName: 'Events',
      apiPath: '/data',
      apiAction: 'read-object',
      apiPayload: apiPayload,
      spinner: {
        content: 'Reading client data. Please Wait...'
      },
      callback: callback
    })
  }))
}

/**
 *
 * @param {*} store
 */
export const unsubscribe = (store) => {
  subscriptions.forEach((item) => PubSub.unsubscribe(item))
}

/**
 *
 * @param {*} store
 * @param {*} topic
 * @param {*} message
 */
export const publish = (store, topic, message) => {
  PubSub.publish(topic, message)
}

/**
 *
 * @param {*} store
 */
export const signOut = async (store) => {
  await Auth.signOut()
}

/**
 *
 * @param {*} store
 */
export const setAuthState = async (store) => {
  const user = Auth.user
  if (user) {
    mergeState(store, {
      controls: {
        user: user
      }
    })
  } else {
    window.location.reload()
  }
}

/**
 *
 * @param {*} store
 */
export const addAuthListener = (store) => {
  setAuthState(store)
  // context.log('Add Hub:Auth Listener')
  Hub.listen('auth', (data) => {
    // context.log('Invoke Hub:Auth Listener')
    setAuthState(store)
  })
}

/**
 *
 * @param {*} store
 */
export const removeAuthListener = (store) => {
  // context.log('Remove Hub:Auth Listener')
  Hub.remove('auth')
}

/**
 * @name setUserData (store, userData)
 * @description Persist key:val pairs as user data to database through API. This persistent data can later be retrieved to
 * set UI customizations.
 * @param store
 * @param userData
 */
export const setUserData = (store, userData) => {
  publish(store, 'callAfter', {
    func: store.actions.api.performApi,
    params: [{
      apiName: 'Events',
      apiPath: '/data',
      apiAction: 'store-user-data',
      apiPayload: {
        userData
      }
    }]
  })
}

/**
 * @name call (store, params)
 * @description Publish an asynchronous event to execute after the current execution context.
 * func parameter is pointer to the function and is invoked with spread operator on params
 * as  func(...params)
 * @param store
 * @param params { func, params }
 */
export const call = (store, params) => {
  publish(store, 'callAfter', {
    func: store.actions.api.performApi,
    params: [params]
  })
}

export const resetForm = (formName) => {
  let form
  if (formName in meta) {
    form = context.copy(meta[formName].data || {})
  } else {
    throw new Error('Form `' + formName + '` does not have a definition entry in meta-data')
  }
  form.formName = formName
  form.dirty = false
  const fields = form.fields
  for (const key in fields) {
    const value = fields[key]
    value.widget = {
      // I put them in this order on purpose.
      // We want the ability to initialize the widget
      // to a non-default value, set to default otherwise.
      id: key,
      name: key,
      title: key,
      ...value.defaults,
      ...value.widget
    }
  }
  const select = []
  const headers = []
  for (const key in fields) {
    if ('dt' in fields[key]) {
      headers.push({
        ...{
          name: fields[key].widget.name,
          label: fields[key].widget.label
        },
        ...fields[key].dt
      })
      select.push(fields[key].widget.column || fields[key].widget.id)
    }
  }
  form.query.params.columns = select
  form.query.headers = headers
  if ('functions' in meta[formName]) {
    form = {
      ...form,
      ...meta[formName].functions
    }
  }
  return form
}

/**
 * @name initForm (store, formName)
 * @param store
 * @param formName
 * @description  Initial load of form if it does not exist. Calls reset form.
 * A form is a collection of inputs and is generally persisted as a single entry in the database.
 * A row for the database is stored as "original" for comparison of changed values.
 * A shadow copy of the object is updated.
 */
export const initForm = (store, formName) => {
  if (!(formName in store.state.forms)) {
    const form = resetForm(formName)
    mergeState(store, {
      forms: {
        [formName]: form,
        current: form
      }
    })
  }
}

/**
 * @name storeDisplayed (store, id)
 * @param store
 * @param id
 * @description  Has something to do with toast and notifications
 */
export const storeDisplayed = (store, id) => {
  let { toast, displayed } = store.state.notifications
  displayed = [...displayed, id]
  store.setState({
    notifications: {
      toast,
      displayed
    }
  })
}

/**
 * @name removeDisplayed (store, id)
 * @param store
 * @param id
 * @description  Has something to do with toast and notifications
 */
export const removeDisplayed = (store, id) => {
  let { toast, displayed } = store.state.notifications
  displayed = [...displayed.filter(key => id !== key)]
  delete toast[id]
  store.setState({
    notifications: {
      toast,
      displayed
    }
  })
}

/**
 * @name onFormEvent (store, formName, event, asynchronous)
 * @param store
 * @param formName
 * @param event
 * @param asynchronous If asynchronous is true then publish the event through pubsub to execute immediately after calling context.
 * @description  Process a form event. This function extracts prepares the data from the event and standardises it into an action object,
 * and evenutally calls setForm to complete the work.
 */
export const onFormEvent = (store, formName, event, asynchronous = true) => {
  let target = event.target
  while (target) {
    if (target.hasAttribute && target.hasAttribute('name') && target.hasAttribute('value')) { break }
    if (target.hasAttribute && target.hasAttribute('type')) { break }
    if (target.type) { break }
    if (target.tagName === 'TEXTAREA') { break }
    target = target.parentNode
  }

  if (!target) {
    context.log('In control.onFormatEvent: Unable to handle widget type from event', event)
    return
  }

  let { name, value, checked, type, tagName } = target
  if (tagName === 'TEXTAREA') {
    type = 'textarea'
  }

  if (type === 'checkbox') {
    value = checked
  }
  // context.log('In onFormEvent tagName, name, value, checked, type', tagName, name, value, checked, type)
  if (asynchronous) {
    publish(store, 'setForm', {
      action: {
        formName,
        type,
        name,
        value
      }
    })
  } else {
    setForm(store, {
      formName,
      type,
      name,
      value
    })
  }
}

/**
 * @name setForm (store, action)
 * @param store
 * @param action
 * @description  Process a form event. Generate a new state by passing the action through setFormReducer.
 * Merge the resultant state into the datastore.
 */
export const setForm = async (store, action) => {
  const newState = await setFormReducer(store, action)
  if (context.isObject(newState)) {
    store.setState(newState)
  }
}

/**
 * @name setFormReducer (store, action)
 * @param store
 * @param action
 * @description  Process a form event. This is where the event actually gets handled.
 * Inputs, buttons and other form elements get handled here.
 */
const setFormReducer = async (store, action) => {
  const { forms } = store.state
  let form = forms[action.formName]
  const { fields, shadow, original, table } = form
  const widget = fields[action.name] && fields[action.name].widget
  const defaults = fields[action.name] && fields[action.name].defaults
  if (action.formName === 'current') {
    action.formName = form.formName
  }
  switch (action.type) {
    case 'setError':
      widget.error = true
      widget.helperText = action.value
      break
    case 'clearError':
      widget.error = false
      widget.helperText = defaults.helperText
      break
    case 'button':
      switch (action.name) {
        case '@clear':
          if (form.loaded && form.dirty) {
            try {
              await context.okCancelPopup(
                <>
                  All changes will be lost. Are you sure you want to clear this object?
                </>,
                'Warning -- Are you sure?'
              )
            } catch {
              Notify(store, 'Delete canceled')
              return
            }
          }
          form = resetForm(action.formName)
          break
        case '@new':
          if (form.loaded && form.dirty) {
            try {
              await context.okCancelPopup(
                <>
                  All changes will be lost. Are you sure you want to clear this object?
                </>,
                'Warning -- Are you sure?'
              )
            } catch {
              Notify(store, 'Delete canceled')
              return
            }
          }
          form.fields.sys_id.widget.value = ''
          form = resetForm(action.formName)
          form.fields.sys_id.widget.value = '@new'
          form.fields.sys_id.widget.variant = 'filled'
          form.fields.sys_id.widget.InputProps = {
            readOnly: true
          }
          form.original = {}
          form.shadow = {}
          form.loaded = true
          break
        case '@delete':
          if (fields.sys_id.widget.value) {
            try {
              await context.okCancelPopup(
                <>
                  This action will delete this object. Deleting is permenant
                  and there is no backup. Are you sure you want to delete this object?
                </>,
                'Warning -- Are you sure?'
              )
            } catch {
              Notify(store, 'Delete canceled')
              return
            }
            const object = {}
            for (const key in fields) {
              object[key] = fields[key].widget.value
            }
            store.actions.api.performApi({
              apiName: 'Events',
              apiPath: '/data',
              apiAction: 'delete-object',
              apiPayload: {
                tableName: form.table,
                object
              },
              callback: (store, response) => {
                if (typeof form.afterDelete === 'function') {
                  publish(store, 'callAfter', { func: form.afterSave, params: [store, form, response] })
                }
                publish(store, 'setForm', { action: { formName: action.formName, type: 'button', name: '@clear' } })
              },
              spinner: {
                content: 'Deleting Data. Please Wait...'
              }
            })
          }
          break
        case '@save-as':
          let newId = null
          try {
            newId = await context.getResultPopup(
              <>
                Enter an ID to save this object as... <br />
                (Leave blank for a new object with a new ID)
              </>,
              'SaveAs...'
            )
          } catch {
            Notify(store, 'Save as canceled')
            return
          }
        // eslint-disable-next-line
        case '@save':
        case '@save-clear':
        case '@save-keep':
        case '@save-new':
          handleSaveMenu(store, action.name)
          if (fields.sys_id.widget.value) {
            const diff = {}
            const object = {}
            for (const key in fields) {
              if (fields[key].widget.displayonly === 'true') {
                continue
              }
              object[key] = fields[key].widget.value
              if (key.search('sys_') !== -1) continue
              if (fields[key].widget.value !== original[key]) {
                diff[key] = fields[key].widget.value
              }
            }
            if (!Object.keys(diff).length && action.name !== '@save-new' && action.name !== '@save-as') {
              Notify(store, 'Nothing Changed. Object save quashed.')
              if (action.name === '@save-clear') {
                publish(store, 'setForm', { action: { formName: action.formName, type: 'button', name: '@clear' } })
              }
              return
            }
            diff.sys_id = original.sys_id
            if (action.name === '@save-new') {
              object.sys_id = '@new'
              diff.sys_id = '@new'
            } else if (object.sys_id === '@new') {
              diff.sys_id = '@new'
            } else if (action.name === '@save-as') {
              if (newId) {
                object.sys_id = newId
                diff.sys_id = newId
              } else {
                object.sys_id = '@new'
                diff.sys_id = '@new'
              }
            }

            if (typeof form.beforeSave === 'function') {
              form.beforeSave(store, form, object, diff)
            }

            const apiPayload = {
              tableName: table,
              object: (action.name === '@save-new' || action.name === '@save-as') ? object : diff
            }

            store.actions.api.performApi({
              apiName: 'Events',
              apiPath: '/data',
              apiAction: 'write-object',
              apiPayload: apiPayload,
              spinner: {
                content: 'Writing Data. Please Wait...'
              },
              callback: (store, response) => {
                if (!response.object) { return }
                if (typeof form.afterSave === 'function') {
                  publish(store, 'callAfter', { func: form.afterSave, params: [store, form, response] })
                }
                if (action.name === '@save-clear') {
                  publish(store, 'setForm', { action: { formName: action.formName, type: 'button', name: '@clear' } })
                }
                publish(store, 'callAfter', { func: Notify, params: [store, `Object ${response.object.sys_id} saved`] })
              },
              stateReducer: (store, response) => {
                if ('object' in response) {
                  // This is a special case that modifies the forms object
                  // which is a complex structure
                  const object = response.object
                  for (const key in object) {
                    if (key in fields) {
                      fields[key].widget.value = object[key]
                    }
                  }
                  form.dirty = false
                  form.original = object
                  form.shadow = context.copy(object)
                  return {
                    forms: {
                      [action.formName]: form,
                      current: form
                    }
                  }
                }
                // Otherwise return the response object for handleResposne and merge
                // into the global state.
                return response
              }
            })
          }
          break
        case '@load':
          // eslint-disable-next-line
          const object = {}
          if (action.value) {
            object.sys_id = action.value
          } else if (form.fields.sys_id.widget.value) {
            object.sys_id = form.fields.sys_id.widget.value
          } else {
            return
          }
          if (form.loaded && form.dirty) {
            try {
              await context.okCancelPopup(
                <>
                  All changes will be lost. Are you sure you want to reload this object from the database?
                </>,
                'Warning -- Are you sure?'
              )
            } catch {
              Notify(store, 'Delete canceled')
              return
            }
          }

          store.actions.api.performApi({
            apiName: 'Events',
            apiPath: '/data',
            apiAction: 'read-object',
            apiPayload: {
              tableName: form.table,
              object
            },
            spinner: {
              content: 'Reading Data. Please Wait...'
            },
            stateReducer: (store, response) => {
              const { object } = response
              for (const key in object) {
                if (key in fields) {
                  fields[key].widget.value = object[key]
                  fields[key].widget.checked = Boolean(object[key])
                  fields[key].widget.selected = Boolean(object[key])
                }
              }
              form.fields.sys_id.widget.variant = 'filled'
              form.fields.sys_id.widget.InputProps = {
                readOnly: true
              }
              form.dirty = false
              form.original = object
              form.shadow = context.copy(object)
              form.loaded = true
              return {
                forms: {
                  [action.formName]: form,
                  current: form
                }
              }
            }
          })
          break
        case '@validate':
          if (form.fields.sys_id.widget.value) {
            if (action.formName in validate) {
              validate[action.formName].validate(form)
            }
          }
          break
        default:
          context.log('In setFormReducer.button: (unhandled)', action)
          break
      }
      break
    case 'text':
    case 'textarea':
    case 'color':
      action.value = action.value.trim()
    // eslint-disable-next-line
    case 'checkbox':
    default:
      if (action.name === 'sys_id') {
        widget.value = action.value
      } else if (form.loaded) {
        if (action.formName in validate) {
          if ('beforeUpdateField' in validate[action.formName]) {
            validate[action.formName].beforeUpdateField(store, action, form)
          }
        }
        widget.value = action.value
        widget.error = false
        widget.selected = Boolean(action.value)
        widget.checked = Boolean(action.value)
        widget.helperText = defaults.helperText
        if (action.value !== original[action.name]) {
          form.dirty = true
        }
        shadow[action.name] = action.value
        if (action.formName in validate) {
          if ('afterUpdateField' in validate[action.formName]) {
            validate[action.formName].afterUpdateField(store, action, form)
          }
        }
      } else if (action.value) {
        await context.alertPopup('Please load or create new object.', 'Form Error')
      }
      break
  }
  return {
    forms: {
      [action.formName]: form,
      current: form
    }
  }
}

/**
 * @name deepMergeState (store, state)
 * @param {*} store
 * @param {*} state
 * @param {bool} overwriteArray if false, merge new arrays with existing arrays. If true, replace existing arrays with new arrays.
 * @description MERGE a new state into the existing state. A wrapper for setState with the deepmerge function.
 */
export const deepMergeState = (store, state, overwriteArray = false) => {
  if (overwriteArray) {
    store.setState(deepmerge(store.state, state, { arrayMerge: overwriteMerge }))
  } else {
    store.setState(deepmerge(store.state, state))
  }
}
/**
 * @param {*} destinationArray
 * @param {*} sourceArray
 * @param {*} options
 * @returns
 */
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray


export const mergeState = (store, newState) => {
  store.setState(context.recursiveAssign(store.state, newState))
}

export const insertState = (store, newState) => {
  store.setState({
    ...store.state,
    ...newState
  })
}

export const setState = (store, newState) => {
  store.setState(newState)
}
/**
 *
 * @param {*} store
 * @param {*} response
 * @returns
 */
export const handleResponse = (store, response) => {
  if (response && response.actions) {
    response.actions.forEach((item) => {
      dispatch(store, item)
    })
    delete response.actions
  }
  return response
}

/**
 * Handle an api response, a list of actions that update the store.
 * @param {*} store
 * @param {*} action
 */
const actionHandler = async (store, action) => {
  switch (action.action) {
    case 'load-object':
      if (context.isTrue(action.payload)) {
        const object = action.payload
        const sys_id = object.sys_id.toString()
        const newState = {
          repo: {
            [object.sys_table]: {
              lookup: {
                [sys_id]: object
              }
            }
          }
        }
        mergeState(store, newState)
      }
      break
    case 'update-store':
      if (context.isTrue(action.payload)) {
        mergeState(store, action.payload)
      }
      break
    case 'console':
      if (context.isTrue(action.payload)) {
        if (action.payload.title) {
          console.log(action.payload)
        } else {
          console.log(action.payload.message)
        }
      }
      break
    case 'file-download':
      if (context.isTrue(action.payload)) {
        FileDownload(context.b64toBlob(action.payload.data), action.payload.filename)
      }
      break
    case 'alert':
      if (context.isTrue(action.payload)) {
        let message = action.payload.message.message || action.payload.message
        let title = action.payload.message.title || action.payload.title
        await context.alertPopup(message, title)
      }
      break
    case 'toast':
      if (!context.isEmpty(action.payload)) {
        NotifyRaw(store, action.payload)
      }
      break
    case 'signout':
      await Auth.signOut()
      break
    case 'redirect':
      if (!context.isEmpty(action.payload)) {
        context.redirect(action.payload.location)
      }
      break
    default:
      throw new Error('Unhandled action ' + JSON.stringify(action))
  }
}

export const handleUnauthorized = (store) => {
  context.alertPopup('You have been signed out and will be returned to the login page.', 'Signed Out')
  window.location.replace('/')
}
/**
 * dispatch an action through pubsub.
 * @param {*} store
 * @param {*} action
 */
export const dispatch = (store, action) => {
  publish(store, 'dispatch', action)
}

/**
 *
 * @param {*} store
 * @param {*} name
 * @returns
 */
export const handleSaveMenu = (store, name) => {
  if (name === '@save-new') return
  if (name === '@save-as') return

  const { saveMenu } = store.state.controls
  let i = saveMenu.length
  while (i--) {
    if (saveMenu[i].name === name) {
      if (i === 0) return

      const item = saveMenu.splice(i, 1)
      saveMenu.unshift(item[0])
      break
    }
  }
  mergeState(store, {
    controls: {
      saveMenu: saveMenu
    }
  })
}

/**
 * Perform an API call onButtonClick. pass parameters stored in data set on button through to api call.
 * @param {*} store
 * @param {*} event
 */
export const onButtonClick = async (store, event, apiCallback) => {
  let current = event.target
  let dataset = {}
  while (current) {
    if (current.dataset && 'action' in current.dataset) {
      dataset = current.dataset
      break
    }
    current = current.parentNode
  }
  if (dataset.title || dataset.warning) {
    try {
      await context.okCancelPopup(dataset.warning,
        dataset.title || 'Warning -- Please Read!'
      )
    } catch {
      Notify(store, 'Action canceled')
      return
    }
  }
  store.actions.api.performApi({
    apiName: 'Events',
    apiPath: '/event',
    apiAction: 'button-click',
    apiPayload: {
      dataset: dataset
    },
    spinner: {
      content: 'Performing requested action. Please wait...'
    },
    callback: apiCallback
  })
}

/**
 * Get Column and Row data for a table by FormName
 * @param {*} store
 * @param {*} tableName
 */
export const NotifyRaw = (store, payload) => {
  const key = new Date().getTime() + Math.random()
  const newState = {
    notifications: {
      toast: {
        [key]: payload
      }
    }
  }
  mergeState(store, newState)
}

/**
 * Get Column and Row data for a table by FormName
 * @param {*} store
 * @param {*} tableName
 */
export const Notify = (store, message, variant = 'success') => {
  NotifyRaw(store, {
    options: {
      variant: variant
    },
    message: message
  })
}

/**
 *
 * @param {*} store
 */
export const beforeLayout = (store) => {

}
