import * as React from 'react'
import {
  Container,
  Typography,
  Box
} from '@mui/material'
import {
  TreeView,
  TreeItem
} from '@mui/lab'
import {
  ExpandMore,
  ChevronRight
} from '@mui/icons-material'
import useGlobal from 'store'
import Header from 'components/Header'
import context from 'lib/context'


let idx = 0
const Component = () => {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()

  const top = (input) => {
    return {
      id: 'root',
      name: 'globalState',
      children: nodes(input)
    }
  }

  const nodes = (input) => {
    const result = []
    for (const key in input) {
      const value = input[key]
      const data = {
        id: `${idx++}`,
        name: `${key}`,
      }
      if (context.isObject(value) || context.isArray(value)) {
        data.children = nodes(value)
      } else {
        data.name = `${key}:  ${JSON.stringify(value)}`
      }
      result.push(data)
    }
    return result
  }

  const renderTree = (nodes) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
      {context.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  )

  return (
    <>
      <Header label='Datastore' />
      <Container
        sx={{
          backgroundColor: 'palette.background.dark',
          paddingBottom: 3,
          paddingTop: 3
        }}
      >
        <Box sx={{
          paddingBottom: 3,
          paddingTop: 3
        }}>
          <Typography>
            This is a debugging tool for programmers. It will only shows up on the
            development system and will not be visible on production.
          </Typography>
        </Box>
        <TreeView
          aria-label='rich object'
          defaultCollapseIcon={<ExpandMore />}
          defaultExpanded={['root']}
          defaultExpandIcon={<ChevronRight />}
          sx={{ flexGrow: 1, overflowY: 'auto' }}
        >
          {renderTree(top(globalState))}
        </TreeView>
      </Container>
    </>
  )
}

export default Component
