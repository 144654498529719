import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import context from 'lib/context'
import useGlobal from 'store'

export default function SignIn () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  return (
    <Container component='main' maxWidth='md'>
      <Box
        sx={{
          backgroundColor: 'white',
          mt: 8,
          p: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >

        <Typography variant='h3'>
          Welcome to CaringCent! Your money is safe with us!
        </Typography>
        <Typography variant='h5'>

        </Typography>
        <Typography variant='body1'>
          <p> Did you know that the average consumer donates more than $10.00 a month in checkout counter change to charities promoted by merchants? What if you could choose the nonprofit that receives this donation...and track the tax deduction? Now you can!</p>
          <p> With CaringCent, supporting your nonprofit organization is easy, fun, and affordable. We believe in "Your Change, Your Choice!" You can now register your credit or debit card with CaringCent and when you shop, your purchase total will be rounded up to the nearest dollar. The difference is then donated to support the cause you care about!</p>
          <p> With CaringCent, you can:</p>
          <ol>
            <li> Track and manage your monthly donation, including setting a monthly maximum contribution</li>
            <li> Retain the tax benefit - CaringCent keeps track of your donations and provides receipts</li>
            <li> Affordably support your favorite nonprofit!</li>
          </ol>
          Your funding credit card will be used for this monthly donation.
          You will be charged once a month on our billing cycle, and it
          will continue until you turn your recurring donation off on this page.
          The minimum recurring donation is ${context.formatMoney(globalState.settings.minimumCharge)}.
        </Typography>
      </Box>
    </Container>
  )
}