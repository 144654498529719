import * as React from 'react'
import useGlobal from 'store'
import Header from 'components/Header'
import context from 'lib/context'
import Card from './SwipeGiveSettingsCard'

import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
} from '@mui/material'

function Component () {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const { rows: swipegiveSettings } = globalState.repo.swipegive_settings || { undefined }

  React.useEffect(() => {
    if (context.isDefined(swipegiveSettings)) return
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'get-swipe-give-settings'
    })
  }, [
    globalActions.api,
    swipegiveSettings
  ])


  const handleAddNew = () => {
    globalActions.api.performApi({
      apiName: 'Events',
      apiPath: '/event',
      apiAction: 'add-swipe-give-settings',
    })
  }

  return <>
    <Header label='SwipeGive Settings' />
    <Container
      sx={{
        paddingBottom: 3,
        paddingTop: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='h4' sx={{ m: 1 }}>
          SwipeGive Settings
        </Typography>
        <Typography variant="body1" sx={{ m: 1 }}>
          Round-ups are when your purchase transactions are rounded-up to the next whole dollar.
          For example, a $1.49 transaction would get "rounded up" to $2.00, and we'd track the 51¢ difference
          to go towards your next donation. A round-up on a full-dollar transaction, like $8.00, could round-up to the next
          whole dollar or down to zero, depending on your settings.
        </Typography>
        <Typography variant="caption" sx={{ m: 1 }}>
          You will be charged once-per-month to your funding card, and the minimum donation is ${context.formatMoney(globalState.settings.minimumCharge)}.
          If you create multiple donations for the same organization, please note you will be charged multiple times.
        </Typography>
      </Box>
      <Box sx={{ position: 'relative', p: 3 }}>
        {swipegiveSettings && context.isTrue(swipegiveSettings) ?
          swipegiveSettings.map((row, idx) => {
            return <Card key={context.genKey(row)} row={row} idx={idx} sx={{ m: 1 }} />
          }) : <Paper>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 5
              }}
            >
              You do not have any SwipeGive donations set up.
            </Box>
          </Paper>}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button variant='outlined' onClick={handleAddNew}>
          Add New SwipeGive Donation
        </Button>
      </Box>
    </Container>
  </>
}

export default Component