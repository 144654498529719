import * as React from 'react'
import useGlobal from 'store'
import { Box } from '@mui/material'

export default function BuildInfo (props) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal()
  const {
    environment,
    vars,
    apiSpinner
  } = globalState.controls

  return (
    <>
      <Box
        sx={{
          m: 2,
          color: 'white'
        }}
      >
        <hr />
        Build Info:&nbsp;
        {environment === 'production' ? '' : environment}&nbsp;
        #{vars ? parseInt(vars.AWS_JOB_ID) : ''}
        <br />
        {vars ? vars.BUILD_DATETIME : ''}
        {apiSpinner.count ? ' [' + apiSpinner.count + ']' : ''}
        <br />
        {vars ? vars.TRID : ''}
      </Box>
    </>
  )
}
